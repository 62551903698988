import React from "react";
import {RegistryUrl} from "../../../../Constants/Global";

export function PopularServiceItem(props) {
    return (
        <a className="text_decoration_none" href={RegistryUrl} target="_blank" rel="noreferrer">
            <div className="swiper-slide">
                <div className="carousel__item">
                    <div className="carousel__media">
                        <img className="img-fluid swiper-lazy" src={props.item.image} width="350" height="220"
                             alt={props.item.title}/>
                    </div>
                    <div className="carousel__caption">
                        <h3 className="carousel__title">{props.item.title}</h3>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default PopularServiceItem
import React, {useEffect, useState} from "react";
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";
import {Link} from "react-router-dom";
import {ApiUrl} from "../../../../Constants/Global";
import axios from "axios";

export const PageOne: React.FC = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        fetchData()
    }, [])

    async function fetchData() {

        await axios.get(ApiUrl + 'contents/item/87dff99c-f980-4e3e-a009-fc30673d1d6f')
            .then(response => {
                setData(response.data)
            })
            .catch(error => {
                console.error(error)
            })

    }

    return (
        <>
            <Header/>

            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                            Що таке єДозвіл
                        </span>
                            </p>
                        </div>
                    </div>

                    <div className="heading heading--search">
                        <div className="heading__container container">
                            <h1 className="heading__title">Що таке єДозвіл</h1>
                        </div>
                    </div>
                </div>
                <div className="main__content">
                    <section className="card-list">
                        <div className="container page-content" dangerouslySetInnerHTML={{ __html: data.description }} />
                    </section>

                </div>

            </main>
            <Footer/>
        </>
    )
}

export default PageOne
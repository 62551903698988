import React from "react";
import {Link, useParams} from "react-router-dom";
import Header from "../../../Layouts/Header";
import ChecksList from "../Checks/ChecksList";
import Footer from "../../../Layouts/Footer";

export function RegisterPage() {

    return (
        <>
            <Header/>
            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
        <span>
            <a href="index.php">Головна</a>
             »
        </span>
                                <span>
            <a href="register.php">Реєстри</a>
             »
        </span>
                                <span>
            <a href="#">Ліцензування провадження освітньої діяльності за рівнем дошкільної освіти Київською міською держадміністрацією</a>
             »
        </span>
                                <span className="breadcrumb_last">Ліцензія №3434345</span>
                            </p>
                        </div>
                    </div>

                </div>
                <div className="main__content">
                    <section className="spoiler spoiler--type-1">
                        <div className="spoiler__container container">
                            <div className="spoiler__list">
                                <div className="spoiler__item spoiler__item--general-data">
                                    <div className="spoiler__head">
                                        <h2 className="spoiler__title">Загальні дані</h2>
                                    </div>
                                    <div className="spoiler__body">
                                        <div className="spoiler__content">
                                            <div className="table-wrap">
                                                <table className="table">
                                                    <caption>Інформація про здобувача ліцензії</caption>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <strong>Найменування юридичної особи</strong>
                                                        </td>
                                                        <td>
                                                            <span className="text-uppercase">ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "СТРІТ"</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>ідентифікаційний код згідно з ЄДРПОУ</strong>
                                                        </td>
                                                        <td>12332567</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="spoiler__item">
                                    <div className="spoiler__head js-spoiler-trigger">
                                        <h2 className="spoiler__title">Зміни в ліцензії</h2>
                                    </div>
                                    <div className="spoiler__body js-spoiler-target">
                                        <div className="spoiler__content">

                                        </div>
                                    </div>
                                </div>
                                <div className="spoiler__item">
                                    <div className="spoiler__head js-spoiler-trigger">
                                        <h2 className="spoiler__title">Судові рішення</h2>
                                    </div>
                                    <div className="spoiler__body js-spoiler-target">
                                        <div className="spoiler__content">

                                        </div>
                                    </div>
                                </div>
                                <div className="spoiler__item">
                                    <div className="spoiler__head js-spoiler-trigger">
                                        <h2 className="spoiler__title">Скарги до ЕАР</h2>
                                    </div>
                                    <div className="spoiler__body js-spoiler-target">
                                        <div className="spoiler__content">

                                        </div>
                                    </div>
                                </div>
                                <div className="spoiler__item">
                                    <div className="spoiler__head js-spoiler-trigger">
                                        <h2 className="spoiler__title">Історія змін</h2>
                                    </div>
                                    <div className="spoiler__body js-spoiler-target">
                                        <div className="spoiler__content">
                                            <div className="table-wrap">
                                                <table className="table table--border">
                                                    <thead>
                                                    <tr>
                                                        <th>ПІБі посадової особи, що внесла запис</th>
                                                        <th>Зміни</th>
                                                        <th>Дата змін</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Олександренко Олександр Олександрович</td>
                                                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Nulla quam velit, vulputate eu pharetra nec, mattis ac
                                                            neque.
                                                        </td>
                                                        <td>12.12.2022</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Олександренко Олександр Олександрович</td>
                                                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Nulla quam velit, vulputate eu pharetra nec, mattis ac
                                                            neque.
                                                        </td>
                                                        <td>12.12.2022</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default RegisterPage
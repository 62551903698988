import React from "react";
import NavLink from "../../Components/NavLink";
import {RegistryUrl} from "../../Constants/Global";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <div className="footer__grid grid">
                    <div className="grid__item">
                        <nav className="footer__nav">
                            <ul>
                                <NavLink to="/auth">Увійти до кабінету органу</NavLink>
                            </ul>
                        </nav>
                    </div>
                    <div className="grid__item">
                        <nav className="footer__nav">
                            <ul>
                                <NavLink to={`/page/scho-take-e-dozvil`}>Що таке єДозвіл</NavLink>
                                {/*<NavLink to={`/page/pytannja-ta-vidpovidy`}>Питання та відповіді</NavLink>*/}
                                {/*<NavLink to={`/page/perelik-poslug`}>Перелік послуг</NavLink>
                                <NavLink to={`/page/dogovir-pryednannja-dlja-partneriv`}>Договір приєднання для партнерів</NavLink>*/}
                            </ul>
                        </nav>
                    </div>
                    <div className="grid__item">
                        <nav className="footer__nav">
                            <ul>
                                {/*<NavLink to="/authorities">Органи</NavLink>*/}
                                <NavLink to="/registers">Реєстри</NavLink>
                                <NavLink to="/complaints">Скарги</NavLink>
                                <NavLink to="/get-services">Отримати послугу</NavLink>
                                <li><a href="https://inspections.gov.ua/" target="_blank" rel="noreferrer">Перевірки</a></li>
                                {/*<li><a href="https://guide.diia.gov.ua/" target="_blank" rel="noreferrer">Гід з державних послуг</a></li>
                                <li><a href="https://prozorro.gov.ua/" target="_blank" rel="noreferrer">Prozorro</a></li>
                                <li><a href="https://id.gov.ua/" target="_blank" rel="noreferrer">id.gov.ua</a></li>
                                <li><a href="https://www.kmu.gov.ua/" target="_blank" rel="noreferrer">Урядовий портал</a></li>
                                */}
                            </ul>
                        </nav>
                    </div>
                    <div className="grid__item">
                        <div className="soc-link">
                            <h2 className="soc-link__title">Слідкуй за нами тут:</h2>
                            <ul>
                                <li><a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><img src="/_assets/img/soc/facebook.svg" width="24" height="24" alt="Facebook"/></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank" rel="noreferrer"><img src="/_assets/img/soc/instagram.svg" width="24" height="24" alt="Instagram"/></a></li>
                                <li><a href="https://twitter.com/" target="_blank" rel="noreferrer"><img src="/_assets/img/soc/twitter.svg" width="24" height="24" alt="Twitter"/></a></li>
                                <li><a href="https://www.youtube.com/" target="_blank" rel="noreferrer"><img src="/_assets/img/soc/youtube.svg" width="24" height="24" alt="Youtube"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
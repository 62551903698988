import React, {useEffect, useState} from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import {Link, useParams} from "react-router-dom";
import {Registers} from "../../Constants/Global";
import Spoiler from "../../Components/Spoiler";
import ReactPDF, {PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Font} from '@react-pdf/renderer';

export const Licency: React.FC = () => {

    const [licency, setLicency] = useState([])
    const [registryTitle, setRegistryTitle] = useState([])
    const [licencyTitle, setLicencyTitle] = useState([])


    const params = useParams();

    useEffect(() => {

        document.body.classList.add('inner-page--result');

        fetchLicency()

        return () => {
            document.body.classList.remove('inner-page--result');
        }
    }, [])

    Font.register({
        family: "Inter",
        src:
            "/_assets/fonts/Inter-Regular.ttf"
    });



    const {registryId, licencyId} = params

    async function fetchLicency() {

        Registers.forEach(function (registry) {
            if (registry.id == registryId) {

                setRegistryTitle(registry.title)
                registry.licencies.forEach(function (licency) {
                    if (licency.id == licencyId) {
                        setLicencyTitle(licency.global_data.license_num)
                        setLicency(licency)
                    }
                })

            }
        })

        if (licency.length < 1) {

        }

    }


    const styles = StyleSheet.create({
        page: {
            fontFamily : "Inter",
            backgroundColor: "white",
            color: "black",
        },
        section: {
            margin: 0,
            padding: 10,
        },
        licencyTitle: {
            fontSize: 18
        },
        title: {
            fontSize: 14
        },
        tableTitle: {
            fontSize: 10,
            fontWeight: "bold"
        },
        viewer: {
            width: window.innerWidth, //the pdf viewer will take up all of the width and height
            height: window.innerHeight,
        },
    });


    const MyDoc = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.licencyTitle}>Ліцензія №{licencyTitle}</Text>
                </View>
                {licency.global_data !== undefined ?
                    <View style={styles.section}>
                        <Text style={styles.title}>Загальні дані</Text>
                        {licency.global_data.rescive_num && <Text style={styles.tableTitle}>Номер рішення про видачу ліцензії: {licency.global_data.rescive_num}</Text>}
                        {licency.global_data.authority_name && <Text style={styles.tableTitle}>Найменування органу ліцензування: {licency.global_data.authority_name}</Text>}
                        {licency.global_data.title && <Text style={styles.tableTitle}>Найменування/ПІБ: {licency.global_data.title}</Text>}
                        {licency.global_data.edrpou && <Text style={styles.tableTitle}>ЄДРПОУ/РНОКПП або серія номер паспорта: {licency.global_data.edrpou}</Text>}
                        {licency.global_data.type && <Text style={styles.tableTitle}>Вид господарської діяльності: {licency.global_data.type}</Text>}
                        {licency.global_data.date_decision && <Text style={styles.tableTitle}>Дата видачі ліцензії: {licency.global_data.date_decision}</Text>}
                        {licency.global_data.license_start && <Text style={styles.tableTitle}>Дата прийняття рішення про видачу ліцензії: {licency.global_data.license_start}</Text>}
                        {licency.global_data.license_term && <Text style={styles.tableTitle}>Строк дії ліцензії: {licency.global_data.license_term}</Text>}
                        {licency.global_data.status && <Text style={styles.tableTitle}>Статус: {licency.global_data.status}</Text>}
                        {licency.global_data.license_stop && <Text style={styles.tableTitle}>Строк зупинення дії ліцензії повністю або частково: {licency.global_data.license_stop}</Text>}
                        {licency.global_data.address && <Text style={styles.tableTitle}>Місце провадження ліцензіатом виду господарської діяльності: {licency.global_data.address}</Text>}
                        {licency.global_data.d1 && <Text style={styles.tableTitle}>Матеріально-технічну базу, що використовується ліцензіатом для провадження виду господарської діяльності, що підлягає ліцензуванню: {licency.global_data.d1}{licency.global_data.d1}</Text>}
                    </View>
                    : null }

                {licency.changes !== undefined ?
                    <View style={styles.section}>
                        <Text style={styles.title}>Зміни в ліцензії</Text>
                        {licency.changes.data_1 &&
                            <Text style={styles.tableTitle}>Дата рішення про анулювання ліцензії повністю або частково: {licency.changes.data_1}</Text>}
                        {licency.changes.data_2 &&
                            <Text style={styles.tableTitle}>Номер рішення про анулювання ліцензії повністю або частково: {licency.changes.data_2}</Text>}
                        {licency.changes.data_3 &&
                            <Text style={styles.tableTitle}>Підстави для анулювання ліцензії повністю або частково: {licency.changes.data_3}</Text>}
                        {licency.changes.data_4 &&
                            <Text style={styles.tableTitle}>Місце провадження діяльності, в якому припиняється провадження виду господарської діяльності, що підлягає ліцензуванню, у разі анулювання ліцензії частков: {licency.changes.data_4}</Text>}
                    </View>
                    : null }

                {licency.history !== undefined ?
                    <View style={styles.section}>
                        <Text style={styles.title}>Історія змін</Text>

                        {licency.history.map(item =>
                            <>
                                <Text style={styles.tableTitle}>ПІБі посадової особи, що внесла запис: {item.pib}</Text>
                                <Text style={styles.tableTitle}>Зміни: {item.status}</Text>
                                <Text style={styles.tableTitle}>Дата зміни: {item.date}</Text>
                            </>
                        )}


                    </View>
                    : null }

            </Page>
        </Document>
    );


    return (
        <>

            <Header/>

            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span>
                            <Link to='/registers'>Реєстри</Link>
                             »
                        </span>
                                <span>
                            <Link to={`/licencies/${registryId}`}>{registryTitle}</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                                {licencyTitle}
                        </span>
                            </p>
                        </div>
                    </div>
                    <div className="heading">
                        <div className="heading__container container">
                            <h1 className="heading__title">Ліцензія №{licencyTitle}</h1>
                            <div className="heading__control">
                                <PDFDownloadLink document={<MyDoc/>} fileName="licency.pdf" className={"text_decoration_none"}>
                                    <span className="heading__load btn cursor-pointer">
                                        <svg className="btn__icon" width="20" height="20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.284 18.542c-4.092 0-5.842-1.75-5.842-5.842v-.108c0-3.7 1.459-5.484 4.725-5.792a.643.643 0 0 1 .684.567.626.626 0 0 1-.567.683c-2.617.242-3.592 1.475-3.592 4.55v.108c0 3.392 1.2 4.592 4.592 4.592h5.433c3.392 0 4.592-1.2 4.592-4.592V12.6c0-3.092-.992-4.325-3.658-4.55a.624.624 0 0 1 .108-1.242c3.317.284 4.8 2.075 4.8 5.8v.109c0 4.075-1.75 5.825-5.842 5.825H7.284Z"
                                                fill="#fff"></path>
                                            <path
                                                d="M9.375 12.4V1.667A.63.63 0 0 1 10 1.042a.63.63 0 0 1 .625.625V12.4a.624.624 0 1 1-1.25 0Z"
                                                fill="#fff"></path>
                                            <path
                                                d="m9.558 13.775-2.792-2.792a.629.629 0 0 1 0-.883.629.629 0 0 1 .884 0L10 12.45l2.35-2.35a.629.629 0 0 1 .883 0 .629.629 0 0 1 0 .883l-2.792 2.792a.618.618 0 0 1-.441.183.618.618 0 0 1-.442-.183Z"
                                                fill="#fff"></path>
                                        </svg>
                                        <span className="btn__text">Завантажити</span>
                                    </span>
                                </PDFDownloadLink>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="main__content">

                    <section className="spoiler spoiler--type-1">
                        <div className="spoiler__container container">
                            <div className="spoiler__list">

                                {licency.global_data !== undefined ?
                                    <div className="spoiler__item spoiler__item--general-data">
                                        <div className="spoiler__head">
                                            <h2 className="spoiler__title">Загальні дані</h2>
                                        </div>
                                        <div className="spoiler__body">
                                            <div className="spoiler__content">
                                                <div className="table-wrap">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Номер рішення про видачу ліцензії</strong>
                                                            </td>
                                                            <td>{licency.global_data.rescive_num}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Найменування органу ліцензування</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.authority_name}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Найменування/ПІБ</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.title}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>ЄДРПОУ/РНОКПП або серія номер паспорта</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.edrpou}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Вид господарської діяльності</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.type}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Дата видачі ліцензії</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.date_decision}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Дата прийняття рішення про видачу
                                                                    ліцензії</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.license_start}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Строк дії ліцензії</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.license_term}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Статус</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.status}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Строк зупинення дії ліцензії повністю або
                                                                    частково</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.license_stop}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Місце провадження ліцензіатом виду господарської
                                                                    діяльності</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.address}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Матеріально-технічну базу, що використовується
                                                                    ліцензіатом для провадження виду господарської
                                                                    діяльності, що підлягає ліцензуванню</strong>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="text-uppercase">{licency.global_data.d1}</span>
                                                            </td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}

                                {licency.changes !== undefined ?
                                    <Spoiler title={'Зміни в ліцензії'}>
                                        <div className="table-wrap">
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Дата рішення про анулювання ліцензії повністю або
                                                            частково</strong>
                                                    </td>
                                                    <td>
                                                        <span className="text-uppercase">{licency.changes.data_1}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Номер рішення про анулювання ліцензії повністю або
                                                            частково</strong>
                                                    </td>
                                                    <td>
                                                        <span className="text-uppercase">{licency.changes.data_2}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Підстави для анулювання ліцензії повністю або
                                                            частково</strong>
                                                    </td>
                                                    <td>
                                                        <span className="text-uppercase">{licency.changes.data_3}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Місце провадження діяльності, в якому припиняється
                                                            провадження виду господарської діяльності, що підлягає
                                                            ліцензуванню, у разі анулювання ліцензії частков</strong>
                                                    </td>
                                                    <td>
                                                        <span className="text-uppercase">{licency.changes.data_4}</span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Spoiler> : null}

                                {licency.history !== undefined ?
                                    <Spoiler title={'Історія змін'}>
                                        <div className="table-wrap">
                                            <table className="table table--border">
                                                <thead>
                                                <tr>
                                                    <th>ПІБі посадової особи, що внесла запис</th>
                                                    <th>Зміни</th>
                                                    <th>Дата зміни</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {licency.history.map(item =>
                                                    <tr>
                                                        <td>{item.pib}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.date}</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Spoiler> : null}


                            </div>
                        </div>
                    </section>

                </div>
            </main>
            <Footer/>
        </>
    )
}

export default Licency
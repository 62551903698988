import React from "react";
import {Link} from "react-router-dom";

export function AuthoritiesListItem(props) {

    return (
        <div className="grid__item">
            <div className="card">
                <Link className="card__link" to={`/authorities/${props.item.id}`}></Link>
                <div className="card__inner">
                    <div className="card__media">
                        <img className="img-fluid" src={props.item.image} width="178" height="102" alt={props.item.title}/>
                    </div>
                    <div className="card__caption">
                        <div className="card__text">
                            <p>{props.item.title}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AuthoritiesListItem
import React from "react";
import {Link} from "react-router-dom";
import NavLink from "../../Components/NavLink";
export class TopNav extends React.Component {

    bodyIsLocked = 'body-locked'
    lowVisionClass = 'low-vision';

    defaultFontSize = 16
    minFontSize = 14
    maxFontSize = 20
    stepFontSize = 2

    lowVisionData = {
        lowVision: false,
        fontSize: this.defaultFontSize
    }

    constructor(props) {
        super(props)

        let lowVisionData = JSON.parse(localStorage.getItem('lowVisionData'))

        if (lowVisionData) {
            this.lowVisionData = lowVisionData
        }

        this.toggleMobile = this.toggleMobile.bind(this)
        this.toggleVisionLowMenu = this.toggleVisionLowMenu.bind(this)
        this.clickVisionLow = this.clickVisionLow.bind(this)
        this.clickVisionLowDefault = this.clickVisionLowDefault.bind(this)
        this.clickIncrease = this.clickIncrease.bind(this)
        this.clickReduce = this.clickReduce.bind(this)

        let increaseClass = ''
        let reduceClass = ''

        if (this.lowVisionData.lowVision) {
            document.body.classList.add(this.lowVisionClass)
            document.getElementById('html').style.fontSize = this.lowVisionData.fontSize + 'px'

            if (this.lowVisionData.fontSize === this.maxFontSize) {
                increaseClass += ' is-disabled'
            }
            if (this.lowVisionData.fontSize === this.minFontSize) {
                reduceClass += ' is-disabled'
            }

        } else {

            document.body.classList.remove(this.lowVisionClass)
            document.getElementById('html').style.fontSize = null
        }

        this.state = {
            mobileMenuVisible: false,
            visionLowMenuVisible: false,
            increaseClass: increaseClass,
            reduceClass: reduceClass,
            lowVision: this.lowVisionData.lowVision,
            fontSize: this.lowVisionData.fontSize
        };
    }


    clickIncrease() {
        if (this.state.fontSize < this.maxFontSize) {
            let newFontSize = this.state.fontSize + this.stepFontSize
            let increaseClass = ''
            if (newFontSize === this.maxFontSize) {
                increaseClass += ' is-disabled'
            }
            document.getElementById('html').style.fontSize = newFontSize + 'px'
            this.lowVisionData.fontSize = newFontSize
            localStorage.setItem('lowVisionData', JSON.stringify(this.lowVisionData))
            this.setState({fontSize: newFontSize, increaseClass: increaseClass, reduceClass: ''})
        }
    }

    clickReduce() {
        if (this.state.fontSize > this.minFontSize) {
            let newFontSize = this.state.fontSize - this.stepFontSize
            let reduceClass = ''
            if (newFontSize === this.minFontSize) {
                reduceClass += ' is-disabled'
            }
            document.getElementById('html').style.fontSize = newFontSize + 'px'
            this.lowVisionData.fontSize = newFontSize
            localStorage.setItem('lowVisionData', JSON.stringify(this.lowVisionData))
            this.setState({fontSize: newFontSize, increaseClass: '', reduceClass: reduceClass})
        }
    }

    clickVisionLow() {
        document.body.classList.add(this.lowVisionClass)
        this.lowVisionData.lowVision = true
        localStorage.setItem('lowVisionData', JSON.stringify(this.lowVisionData))
    }

    clickVisionLowDefault () {
        document.body.classList.remove(this.lowVisionClass)
        document.getElementById('html').style.fontSize = null
        this.setState({fontSize: this.defaultFontSize, increaseClass: '', reduceClass: ''})
        this.lowVisionData.lowVision = false
        this.lowVisionData.fontSize = this.defaultFontSize
        localStorage.setItem('lowVisionData', JSON.stringify(this.lowVisionData))
    }

    toggleVisionLowMenu() {
        if (this.state.visionLowMenuVisible === true) {
            this.setState({visionLowMenuVisible: false})
        } else {
            this.setState({visionLowMenuVisible: true})
            this.setState({mobileMenuVisible: false})
            document.body.classList.remove(this.bodyIsLocked)
        }
    }

    toggleMobile() {
        if (this.state.mobileMenuVisible === true) {
            document.body.classList.remove(this.bodyIsLocked)
            this.setState({mobileMenuVisible: false})
        } else {
            this.setState({mobileMenuVisible: true})
            this.setState({visionLowMenuVisible: false})
            document.body.classList.add(this.bodyIsLocked)
        }
    }

    render() {
        return (
            <div className="container header__container">
                <Link to="/" className="header__logo">
                    <img className="img-fluid" src="/_assets/img/logo.svg" width="123" height="24" alt="ePermit"/>
                </Link>
                <div className="header__right">
                    <nav className={this.state.mobileMenuVisible ? 'header__nav header__nav--open' : 'header__nav'}>
                        <ul>
                            <NavLink to={`/page/scho-take-e-dozvil`}>Що таке єДозвіл</NavLink>
                            {/*<NavLink to="/authorities">Органи</NavLink>*/}
                            <NavLink to="/registers">Реєстри</NavLink>
                            <NavLink to="/complaints">Скарги</NavLink>
                            <li><a href="https://inspections.gov.ua/" target="_blank" rel="noreferrer">Перевірки</a></li>
                            {/*<NavLink to="/checks">Перевірки</NavLink>*/}
                            {/*<NavLink to="/get-services">Отримати послугу</NavLink>*/}
                        </ul>
                        <Link to="/get-services" className="btn header__btn header__btn--m"><span className="btn__text">Отримати послугу</span></Link>
                    </nav>
                    <Link to="/get-services" className="btn header__btn header__btn--d"><span className="btn__text">Отримати послугу</span></Link>
                    <div className={this.state.visionLowMenuVisible ? 'header-set header-set--open' : 'header-set'}>
                        <div className="header-set__control" onClick={this.toggleVisionLowMenu}>
                            <a className="header-set__link" style={{cursor: "pointer"}}>
                                <svg width="24" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.824 16.741c.034 1.276.162 1.598 1.758 1.713v.971H2.89v-.971c1.596-.115 1.772-.437 1.806-1.713l-.001-7.936c-2.66-.09-3.099.474-3.501 2.305H0c.231-1.049.46-2.05.46-3.487h10.598c0 1.437.116 2.438.347 3.487h-1.17c-.402-1.831-.752-2.395-3.411-2.305v7.936m11.135-.241c.038 1.022.525 1.668 2.343 1.76v1.165h-7.067V18.26c1.818-.092 2.26-.737 2.3-1.76l-.001-14.356c-3.422-.082-4.363.308-4.654 2.625H9.492c.298-1.194.252-2.556.252-4.193h13.81c0 1.637.149 2.999.446 4.193h-1.503c-.517-2.086-1.509-2.728-4.538-2.625V16.5"
                                        fill="#000"/>
                                </svg>
                            </a>
                        </div>
                        <div className="header-set__dropdown">
                            <ul className="header-set__list">
                                <li className="header-set__vision">
                                    <button className="header-set__btn js-vision-low" type="button" onClick={this.clickVisionLow}>
                                        <svg className="icon icon-eye-minus">
                                            <use xlinkHref="#icon-eye-minus"></use>
                                        </svg>
                                    </button>
                                    <button className="header-set__btn js-vision-default" type="button" onClick={this.clickVisionLowDefault}>
                                        <svg className="icon icon-eye">
                                            <use xlinkHref="#icon-eye"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li className="header-set__font-size">
                                    <button onClick={this.clickIncrease} className={'header-set__btn js-fz-increase is-active ' + this.state.increaseClass} type="button">тТ +</button>
                                </li>
                                <li className="header-set__font-size">
                                    <button onClick={this.clickReduce} className={'header-set__btn js-fz-reduce is-active ' + this.state.reduceClass} type="button">тТ -</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <a className={this.state.mobileMenuVisible ? 'header__toggle header__toggle--open' : 'header__toggle'} style={{cursor: "pointer"}} onClick={this.toggleMobile}><span>Меню</span></a>
            </div>
        )
    }

}
export default TopNav;


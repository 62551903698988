import React, {useEffect, useState} from 'react';
import ServicesListItem from './ServicesListItem'
import {Services} from "../../../../Constants/Global";
//import axios from "axios";

export const ServicesList: React.FC = () => {
    const [servicesList, setServicesList] = useState([])

    useEffect(() => {
        fetchServicesList()
    }, [])

    async function fetchServicesList() {
        setServicesList(Services)
        //const response = await axios.get('https://jsonplaceholder.typicode.com/photos?_limit=20')
        //setServicesList(response.data)
    }

    return (
        <>
            <section className="link-block">
                <div className="link-block__container container">
                    <ul className="link-block__list">
                        {servicesList.map(service =>
                            <ServicesListItem item={service} key={service.id}/>
                        )}
                    </ul>
                </div>
            </section>
        </>
    )
}

export default ServicesList
import React from "react";
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";
import {Link} from "react-router-dom";

export function PageTwo() {
    return (
        <>
            <Header/>

            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                            Питання та відповіді
                        </span>
                            </p>
                        </div>
                    </div>

                    <div className="heading heading--search">
                        <div className="heading__container container">
                            <h1 className="heading__title">Питання та відповіді</h1>
                        </div>
                    </div>
                </div>
                <div className="main__content">
                    <section className="card-list">
                        <div className="container">
                            <h3>Що таке річний план перевірок і чому він важливий для мене?</h3>
                            <p className="page-content">Вас як власника бізнесу (незалежно від того, працюєте Ви як
                                фізична особа-підприємець (ФОП) чи з використанням юридичної особи (наприклад, ТОВ, ПП і
                                т.д.) очевидно хвилює, чи доведеться Вам (і коли саме) витрачати свій час у зв'язку з
                                проведенням перевірки Вашого бізнесу тим чи іншим органом державного контролю.
                            </p>
                            <p className="page-content">Перш за все, зауважимо, що перевірки бувають плановими та
                                позаплановими.</p>

                            <p className="page-content">Прогнозувати позапланові перевірки складно, оскільки здебільшого
                                вони настають через настання певних неприємних обставин. Тому про них поговоримо лише
                                побіжно.</p>

                            <p className="page-content"> Планова перевірка за визначенням є заздалегідь запланованим
                                візитом державного інспектора, що здійснюється згідно з річним планом перевірок.</p>

                            <p className="page-content">Річний план перевірок – це перелік суб’єктів господарювання,
                                яких конкретна інспекція має намір перевірити наступного року (наступного за роком, в
                                якому такий план складається).</p>

                            <p className="page-content">Слід зауважити, що кожна інспекція (а їх в Україні нараховується
                                більше 30-ти) складає свій власний річний план перевірок. Тому Ваш бізнес може
                                фігурувати у планах одразу кількох інспекцій.</p>

                            <p className="page-content">Найважливішою у всій цій історії є обов'язковість оприлюднення
                                відповідних річних планів, тож у Вас є можливість дуже легко з'ясувати, інспектори яких
                                органів контролю планують завітати до Вас з плановими перевірками.</p>

                            <h3>Коли можна побачити річний план на наступний рік?</h3>

                            <p className="page-content">Органи контролю повинні підготувати і оприлюднити проекти своїх
                                річних планів на наступний рік не пізніше 15 жовтня поточного року. Це правило не
                                розповсюджується на органи, для яких законодавством передбачена інша процедура
                                підготовки плану перевірок (наприклад, Державну фіскальну службу).</p>

                            <p className="page-content">Однак, проект плану, підготовлений до 15 жовтня, ще не є
                                остаточним – до нього можуть бути внесені зміни. Наприклад, може бути змінена дата
                                початку перевірки суб’єкта господарювання, якщо він потрапив до комплексної перевірки
                                (що таке комплексні перевірки – див. нижче).</p>

                            <p className="page-content">Не пізніше 1 грудня кожна інспекція повинна затвердити свій
                                річний план перевірок на наступний рік. Саме цей затверджений річний план є
                                остаточним.</p>

                            <p className="page-content">Джерело: частина 1 статті 5 Закону України «Про основні засади
                                державного нагляду (контролю) у сфері господарської діяльності»</p>

                            <h3>Після 1 грудня на Інспекційному порталі відсутні річні плани деяких органів контролю
                                (навпроти їх назви стоїть нуль). Що це означає?</h3>

                            <p className="page-content">Це означає, що в наступному році такі органи не здійснюватимуть
                                планових перевірок. Проте, це не стосується позапланових заходів – вони можуть бути
                                проведені за наявності підстав, визначених Законом України «Про основні засади
                                державного нагляду (контролю) у сфері господарської діяльності».</p>

                            <p className="page-content">Увага! Це правило, знову ж таки, не стосується деяких органів,
                                щодо яких встановлені особливі правила. До них належить Державна фіскальна служба та
                                Державна авіаційна служба.</p>


                        </div>
                    </section>

                </div>

            </main>
            <Footer/>
        </>
    )
}

export default PageTwo
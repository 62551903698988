import React, {useEffect} from "react";

import Header from './Header'
import Footer from './Footer'
import Home from "./../Pages/Home";

export function HomeLayout() {

    useEffect(() => {
        document.body.classList.remove('inner-page');
    }, []);

    return (
        <>
            <Header/>
            <Home/>
            <Footer/>
        </>
    )
}

export default HomeLayout

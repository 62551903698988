import React, {useEffect, useState} from "react";
import Select from 'react-select'

export function SelectInput({title, placeholder, options}) {

    return (
        <div className="grid__item">
            <div className="search-expand__input-group">
                <label className="search-expand__label">{title}</label>
                <Select isClearable={true} options={options} placeholder={placeholder} className="search-expand__select js-select"/>
            </div>
        </div>
    )
}

export default SelectInput
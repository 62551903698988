import React from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import AuthoritiesList from "./Partial/Authorities/AuthoritiesList";
import {Link} from "react-router-dom";

export function Authorities() {

    return (
        <>
            <Header/>
            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                            Органи
                        </span>
                            </p>
                        </div>
                    </div>
                    <div className="heading">
                        <div className="heading__container container">
                            <h1 className="heading__title">Органи</h1>
                        </div>
                    </div>
                </div>
                <div className="main__content">
                    <AuthoritiesList/>
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default Authorities
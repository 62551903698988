import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import HomeLayout from "./Views/Layouts/HomeLayout";
import Layout from "./Views/Layouts/Layout";
import './Assets/Styles/app.min.css'
import './Assets/Styles/e-permit.css'
import './Assets/Styles/datatables.min.css'

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<HomeLayout/>}></Route>
                <Route path="*" element={<Layout/>}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;

import React from "react";
import {Link} from "react-router-dom";
import {RegistryUrl} from "../../Constants/Global";

export function Auth() {

    function handleSubmit(e) {
        e.preventDefault();
    }

    return (
        <section className="support support--logup">
            <div className="support__container container">
                <div className="support__content">
                    <Link to={'/'} className="support__logo">
                        <img className="img-fluid" src="/_assets/img/logo.svg" width="123" height="24" alt="ePermit"/>
                    </Link>
                    <h1 className="support__title">Увійти до кабінету</h1>
                    <form className="logup-form" onSubmit={handleSubmit}>
                        <div className="logup-form__input-group is-error">
                            <label className="logup-form__label" htmlFor="">Орган</label>
                            <select className="js-select" name="">
                                <option value="" disabled selected>Виберіть орган із списку</option>
                                <option value="">Міністерство економіки України</option>
                                <option value="">Державна служба України з питань безпечності харчових продуктів та захисту споживачів</option>
                                <option value="">Державна податкова служба України</option>
                                <option value="">Державна регуляторна служба України</option>
                                <option value="">Державна служба України з питань праці</option>
                                <option value="">Державне агентство розвитку туризму</option>
                                <option value="">Київська міська державна адміністрація</option>
                            </select>
                        </div>
                        <div className="logup-form__input-group">
                            <label className="logup-form__label" htmlFor="">Реєстр</label>
                            <select className="js-select" name="">
                                <option value="" disabled selected>Виберіть реєстр із списку</option>
                                <option value="tourist">Ліцензування провадження туроператорської діяльності</option>
                                <option value="veterinary">Ліцензування провадження ветеринарної практики</option>
                                <option value="education">Ліцензування провадження освітньої діяльності</option>
                                <option value="drugs">Ліцензування провадження господарської діяльності з використанням наркотичних засобів</option>
                                <option value="retail">Ліцензування роздрібної торгівлі (алкоголю, тютюнових виробів і рідин в електросигаретах)</option>
                                <option value="bulk">Ліцензування оптової торгівлі (алкоголю, тютюнових виробів і рідин в електросигаретах)</option>
                                <option value="fuel_bulk">Ліцензування оптової торгівлі пальним</option>
                                <option value="fuel_storage">Ліцензування на зберігання пального</option>
                            </select>
                        </div>
                        <div className="logup-form__input-group">
                            <button className="logup-form__btn btn">
                                <span className="btn__text"><a href={RegistryUrl} target="_blank" rel="noreferrer">Увійти до кабінету</a></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )

}

export default Auth
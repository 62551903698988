import React from "react";
import {Link} from "react-router-dom";

export function RegistersList(props) {
    return (
        <li className="link-block__item">
            {/*<a className="link-block__link" href={props.item.url} target="_blank" rel="noreferrer">{props.item.title}</a>*/}
            <Link className="link-block__link" to={`/licencies/${props.item.id}`}>{props.item.title}</Link>
        </li>
    )
}
export default RegistersList
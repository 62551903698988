import React, {useEffect, useState} from 'react';
import RegistersListItem from './RegistersListItem'
//import axios from "axios";
import {Registers} from "../../../../Constants/Global"

export const AuthorityRegistersList: React.FC = () => {
    const [registersList, setRegistersList] = useState([])

    useEffect(() => {
        fetchRegistersList()
    }, [])

    async function fetchRegistersList() {

        setRegistersList(Registers)

        //const response = await axios.get('https://jsonplaceholder.typicode.com/photos?_limit=20')
        //setAuthoritiesList(response.data)
    }

    return (
        <>
            <section className="link-block">
                <div className="link-block__container container">
                    <ul className="link-block__list">
                        {registersList.map(register =>
                            <RegistersListItem item={register} key={register.id}/>
                        )}
                    </ul>
                </div>
            </section>
        </>
    )
}

export default AuthorityRegistersList
import React, {useEffect, useState} from 'react';

import {Services, Registers} from '../Constants/Global'
import AutoComplete from "./AutoComplete";

function SearchForm() {

    const [data, setData] = useState()


    useEffect(() => {


        let suggestion = []

        Registers.forEach(function (register) {
            suggestion.push(register.title)
        })

        Services.forEach(function (register) {
            suggestion.push(register.title)
        })

        setData(suggestion)


    }, [])

    return (
        <AutoComplete data={data}/>
    )

}

export default SearchForm
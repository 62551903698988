import React, {useEffect, useState} from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import {Link, useParams} from "react-router-dom";
import Table from "../../Components/Table"
import {Registers} from "../../Constants/Global";
import DateInput from "../../Components/DateInput";
import SelectInput from "../../Components/SelectInput";

export function Licencies() {

    const columns = React.useMemo(
        () => [
            {
                Header: "Найменування замовника (для юросіб) / ПІБ замовника (для фізосіб)",
                accessor: "global_data.title",
                width: 300,
                justify: "center"
            },
            {
                Header: "Код ЄДРПОУ (для юросіб) / РНОКПП (для фізосіб)",
                accessor: "global_data.edrpou"
            },
            {
                Header: "Вид господарської діяльності",
                accessor: "global_data.type"
            },
            {
                Header: "Дата видачі ліцензії / Дата прийняття рішення про видачу ліцензії",
                accessor: "global_data.date_decision"
            },
            {
                Header: "Номер ліцензії",
                accessor: "global_data.license_num"
            },
            {
                Header: "Строк дії ліцензії",
                accessor: "global_data.license_term"
            },
            {
                Header: "Статус",
                accessor: "global_data.status"
            },

        ], []
    )


    const [licenciesList, setLicenciesList] = useState([])
    const [searchFilter, setSearchFilter] = useState({
        global_data__title: '',
        global_data__type: '',
        global_data__license_num: ''
    })

    const [registryTitle, setRegistryTitle] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const params = useParams();

    const {registryId} = params

    function toggleSearch() {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        Registers.forEach(function (registry) {
            if (registry.id == registryId) {
                setRegistryTitle(registry.title)
                if (registry.licencies && registry.licencies.length > 0) {
                    setLicenciesList(registry.licencies)
                }
            }
        })
    }, [])


    const filterData = (licencies) => {
        return (licencies.global_data.title.toLowerCase().includes(searchFilter.global_data__title)
                || licencies.global_data.edrpou.toLowerCase().includes(searchFilter.global_data__title))
            && licencies.global_data.type.toLowerCase().includes(searchFilter.global_data__type)
            && licencies.global_data.license_num.toLowerCase().includes(searchFilter.global_data__license_num)
    }

    function handleSearchInput(e) {
        setSearchFilter({...searchFilter, [e.target.name]: e.target.value.toLowerCase()})
    }

    return (<>
            <Header/>
            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span>
                            <Link to='/registers'>Реєстри</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                                {registryTitle}
                        </span>
                            </p>
                        </div>
                    </div>

                    <div className="heading heading--search-expand">
                        <div className="heading__container container">
                            <h1 className="heading__title">Ліцензії</h1>
                            <form className="search">
                                <div className="search__top">
                                    <div className="search__top-inner">
                                        <div className="search__group">
                                            <div className="search__group-inner">
                                                <input
                                                    className="search__input" type="text"
                                                    onChange={handleSearchInput}
                                                    value={searchFilter.global_data__title}
                                                    name="global_data__title"
                                                    placeholder="Назва / Код суб'єкта..."/>
                                            </div>
                                            <button className="search__btn btn" type="button">
                                                <span className="btn__text">Шукати</span>
                                            </button>
                                        </div>
                                        <button
                                            className={isOpen ? 'search-expand-btn btn btn--transparent search-expand-btn--open' : 'search-expand-btn btn btn--transparent'}
                                            type="button"
                                            onClick={toggleSearch}>
                                            <span className="btn__text">Розширений пошук</span>
                                            <svg className="btn__icon" width="20" height="20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd" clipRule="evenodd"
                                                    d="M2.447 6.228a.937.937 0 0 1 1.325-.031l6.012 5.738c.121.116.311.116.432 0l6.012-5.738a.937.937 0 1 1 1.294 1.356l-6.012 5.739a2.187 2.187 0 0 1-3.02 0L2.478 7.553a.937.937 0 0 1-.031-1.325Z"
                                                    fill="#000" fillOpacity=".7"/>
                                            </svg>
                                        </button>
                                    </div>
                                    {/*<a className="search__btn search__btn--load-desk btn" href="#" download>
                                            <svg className="btn__icon" width="20" height="20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.284 18.542c-4.092 0-5.842-1.75-5.842-5.842v-.108c0-3.7 1.459-5.484 4.725-5.792a.643.643 0 0 1 .684.567.626.626 0 0 1-.567.683c-2.617.242-3.592 1.475-3.592 4.55v.108c0 3.392 1.2 4.592 4.592 4.592h5.433c3.392 0 4.592-1.2 4.592-4.592V12.6c0-3.092-.992-4.325-3.658-4.55a.624.624 0 0 1 .108-1.242c3.317.284 4.8 2.075 4.8 5.8v.109c0 4.075-1.75 5.825-5.842 5.825H7.284Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="M9.375 12.4V1.667A.63.63 0 0 1 10 1.042a.63.63 0 0 1 .625.625V12.4a.624.624 0 1 1-1.25 0Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="m9.558 13.775-2.792-2.792a.629.629 0 0 1 0-.883.629.629 0 0 1 .884 0L10 12.45l2.35-2.35a.629.629 0 0 1 .883 0 .629.629 0 0 1 0 .883l-2.792 2.792a.618.618 0 0 1-.441.183.618.618 0 0 1-.442-.183Z"
                                                    fill="#fff"/>
                                            </svg>
                                            <span className="btn__text">Завантажити результати</span>
                                        </a>*/}
                                </div>
                                <div className="search__bottom">
                                    <div
                                        className={isOpen ? 'search-expand search-expand--open' : 'search-expand'}
                                        style={{display: isOpen ? 'block' : 'none'}}>
                                        <div className="grid">
                                            <div className="grid__item grid__item">
                                                <div className="search-expand__input-group">
                                                    <label className="search-expand__label">Найменування виду
                                                        господарської діяльності</label>
                                                    <div className="search-expand__t-search">
                                                        <input className="search-expand__input" type="text"
                                                               placeholder="Пошук"
                                                               onChange={handleSearchInput}
                                                               value={searchFilter.global_data__type}
                                                               name="global_data__type"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <SelectInput title="Статус" placeholder="Виберіть статус із списку"
                                                         options={[
                                                             {value: 1, label: 'Відмова'},
                                                             {value: 2, label: 'Залишено без розгляду'},
                                                             {value: 3, label: 'Зупинено'},
                                                             {value: 4, label: 'Анулювано'},
                                                             {value: 5, label: 'Розширено'},
                                                             {value: 6, label: 'Звужено'}
                                                         ]}/>

                                            <DateInput
                                                title="Дата видачі ліцензії / дата прийняття рішення про видачу ліцензії"
                                                placeholder="Вибрати дату"/>

                                            <div className="grid__item">
                                                <div className="search-expand__input-group">
                                                    <label className="search-expand__label">Номер ліцензії / Номер
                                                        рішення про видачу ліцензії</label>
                                                    <div className="search-expand__t-search">
                                                        <input className="search-expand__input" type="text"
                                                               onChange={handleSearchInput}
                                                               value={searchFilter.global_data__license_num}
                                                               name="global_data__license_num"
                                                               placeholder="Пошук"/>
                                                    </div>
                                                </div>
                                            </div>


                                            <DateInput title="Cтрок дії ліцензії" placeholder="Вибрати дату"/>

                                            <DateInput title="Дата рішення" placeholder="Вибрати дату"/>

                                            <div className="grid__item">
                                                <div className="search-expand__input-group">
                                                    <label className="search-expand__label">Номер рішення про зняття
                                                        скарги з розгляду</label>
                                                    <div className="search-expand__t-search">
                                                        <input className="search-expand__input" type="text" name=""
                                                               placeholder="Пошук"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid__item">
                                                <div className="search-expand__input-group">
                                                    <label className="search-expand__label">Підстава</label>
                                                    <div className="search-expand__t-search">
                                                        <input className="search-expand__input" type="text" name=""
                                                               placeholder="Пошук"/>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    {/*<a className="search__btn search__btn--load-mob btn" href="#" download>
                                            <svg className="btn__icon" width="20" height="20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.284 18.542c-4.092 0-5.842-1.75-5.842-5.842v-.108c0-3.7 1.459-5.484 4.725-5.792a.643.643 0 0 1 .684.567.626.626 0 0 1-.567.683c-2.617.242-3.592 1.475-3.592 4.55v.108c0 3.392 1.2 4.592 4.592 4.592h5.433c3.392 0 4.592-1.2 4.592-4.592V12.6c0-3.092-.992-4.325-3.658-4.55a.624.624 0 0 1 .108-1.242c3.317.284 4.8 2.075 4.8 5.8v.109c0 4.075-1.75 5.825-5.842 5.825H7.284Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="M9.375 12.4V1.667A.63.63 0 0 1 10 1.042a.63.63 0 0 1 .625.625V12.4a.624.624 0 1 1-1.25 0Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="m9.558 13.775-2.792-2.792a.629.629 0 0 1 0-.883.629.629 0 0 1 .884 0L10 12.45l2.35-2.35a.629.629 0 0 1 .883 0 .629.629 0 0 1 0 .883l-2.792 2.792a.618.618 0 0 1-.441.183.618.618 0 0 1-.442-.183Z"
                                                    fill="#fff"/>
                                            </svg>
                                            <span className="btn__text">Завантажити результати</span>
                                        </a>*/}
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
                <div className="main__content">
                    <section className="data">
                        <div className="data__container container">
                            <div className="data__table">
                                <Table columns={columns} data={licenciesList.filter(filterData)} urlPrefix="/licencies/1/"/>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default Licencies
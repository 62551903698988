import React from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import {Link} from "react-router-dom";
import AuthoritiesList from "./Partial/Authorities/AuthoritiesList";
import ServicesList from "./Partial/Services/ServicesList";

export function GetServices() {
    return (
        <div>
            <Header/>
            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                            Отримати послугу
                        </span>
                            </p>
                        </div>
                    </div>
                    <div className="heading">
                        <div className="heading__container container">
                            <h1 className="heading__title">Отримати послугу</h1>
                        </div>
                    </div>
                </div>
                <div className="main__content">
                    <ServicesList/>
                </div>
            </main>
            <Footer/>
        </div>
    )

}

export default GetServices
import React from "react";
import {Link} from "react-router-dom";

export function NotFound() {
    return (
        <section className="support">
            <div className="support__container container">
                <div className="support__content">
                <span className="support__logo">
                    <img className="img-fluid" src="/_assets/img/logo.svg" width="123" height="24" alt="ePermit"/>
                </span>
                    <img className="support__img img-fluid" src="/_assets/img/404.svg" width="440" height="149"
                         alt="Такої сторінки не існує"/>
                    <h1 className="support__title">Такої сторінки не існує</h1>
                    <p className="support__sub-title">Схоже, це неправильна адреса, або сторінка переїхала</p>
                    <Link to="/" className="support__btn btn"><span className="btn__text">На головну</span></Link>
                </div>
                <img className="support__bg" src="/_assets/img/support-bg.png" width="1420" height="995"
                     alt="Такої сторінки не існує"/>
            </div>
        </section>
    )
}

export default NotFound
import React from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

function NavLink({children, to}) {
    const location = useLocation();
    let match = location.pathname === to;

    if (location.pathname && location.pathname.length > 0) {
        let startPath = location.pathname.split('/')[1]
        switch (startPath) {
            case 'complaint':
                if (to == '/complaints') {
                    match = true
                }
                break;
            case 'licencies':
                if (to == '/registers') {
                    match = true
                }
                break;
        }
    }

    return (
        <li className={match ? "current-menu-item" : ""}>
            <Link to={to}>{children}</Link>
        </li>
    );
}

export default NavLink;
import React, {useEffect, useState} from 'react';
import PopularServiceItem from './PopularServiceItem'
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation, Lazy} from "swiper";
//import 'swiper/swiper.min.css';
import './../../../../Assets/Styles/swiper-bundle.min.css'
import {PopularServices as PopularServicesItems} from'../../../../Constants/Global'
export const PopularServices: React.FC = () => {
    const [popularServices, setPopularServices] = useState([])

    useEffect(() => {
        fetchPopularServices()
    }, [])

    async function fetchPopularServices() {
        setPopularServices(PopularServicesItems)
       // const response = await axios.get('https://jsonplaceholder.typicode.com/photos?_limit=10')
       // setPopularServices(response.data)
    }

    return (
        <section className="services">
            <div className="services__container container">
                <div className="services__heading">
                    <h2 className="services__title">Популярні послуги</h2>
                    <div className="services__swiper-navigation swiper-navigation">
                        <div className="swiper-button swiper-button-prev">
                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M16.527 2.936c.45.43.466 1.141.037 1.59l-6.887 7.215a.375.375 0 0 0 0 .518l6.887 7.214a1.125 1.125 0 0 1-1.628 1.554L8.05 13.812a2.625 2.625 0 0 1 0-3.624l6.886-7.215a1.125 1.125 0 0 1 1.59-.037Z"
                                      fill="#000" fillOpacity=".7"/>
                            </svg>
                        </div>
                        <div className="swiper-button swiper-button-next">
                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M7.473 21.064a1.125 1.125 0 0 1-.037-1.59l6.887-7.215a.375.375 0 0 0 0-.518L7.436 4.527a1.125 1.125 0 1 1 1.628-1.554l6.886 7.215a2.625 2.625 0 0 1 0 3.624l-6.886 7.215a1.125 1.125 0 0 1-1.59.037Z"
                                      fill="#000" fillOpacity=".7"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="services__carousel carousel swiper">
                    <div className="swiper-wrapper">
                        <Swiper
                            modules={[Navigation, Pagination, Lazy]}
                            preloadImages={false}
                            lazy={true}
                            spaceBetween={30}
                            freeMode={false}
                            centeredSlides={false}
                            slidesPerView={1}
                            touchRatio={0.2}
                            slideToClickedSlide={true}
                            pagination={{
                                clickable: true,
                                el: ".swiper-pagination"
                            }}
                            navigation={
                                {
                                    nextEl: document.querySelector('.services__swiper-navigation .swiper-button-next'),
                                    prevEl: document.querySelector('.services__swiper-navigation .swiper-button-prev')
                                }
                            }
                            breakpoints={
                                {
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 30
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 24
                                    },

                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 60
                                    }
                                }
                            }
                        >
                            {popularServices.map(popularService =>
                                <SwiperSlide>
                                    <PopularServiceItem item={popularService} key={popularService.id}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    )
}

export default PopularServices
import React from 'react';

function Search({ children, to }) {
    return (<></>)

}
export default Search;
/*
return (
        <>
            <form className="search">
                <div className="search__group">
                    <div className="search__group-inner">
                        <input className="search__input" type="text" name="heading-search"
                               id="heading-search" placeholder="Пошук..."/>
                    </div>
                    <button className="search__btn btn" type="submit">
                        <span className="btn__text">Шукати</span>
                    </button>
                </div>
            </form>
        </>
    );
 */
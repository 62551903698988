import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import Authorities from "../Pages/Authorities";
import Registers from "../Pages/Registers";
import Complaints from "../Pages/Complaints";
//import Checks from "../Pages/Checks";
import GetServices from "../Pages/GetServices";
import Auth from "../Pages/Auth";
import NotFound from "../Pages/NotFound";
import AuthorityRegistersList from "../Pages/Partial/Authorities/AuthorityRegistersList";
//import LicencyPage from "../Pages/Partial/Authorities/LicencyPage";
import RegisterPage from "../Pages/Partial/Registers/RegisterPage";
import PageOne from "../Pages/Partial/Pages/PageOne";
import PageTwo from "../Pages/Partial/Pages/PageTwo";
import PageTree from "../Pages/Partial/Pages/PageTree";
import PageFour from "../Pages/Partial/Pages/PageFour";
import Licencies from "../Pages/Licencies";
import Licency from "../Pages/Licency";
import Complaint from "../Pages/Complaint";

export function Layout() {

    useEffect(() => {
        document.body.classList.add('inner-page');
    }, []);

    return (
        <Routes>
            <Route
                path="authorities"
                element={<Authorities/>}
            >
            </Route>
            {/*<Route path="authorities/:authorityId" element={<AuthorityRegistersList/>}/>*/}
            {/*<Route path="authorities/:authorityId/:registryId" element={<Licencies/>}/>*/}

            <Route path="licencies/:registryId" element={<Licencies/>}/>
            <Route path="licencies/:registryId/:licencyId" element={<Licency/>}/>


            <Route path="authorities/:authorityId/:registryId/:licencyId" element={<Licency/>}/>

            <Route path="registers" element={<Registers/>}/>
            <Route path="registers/:registerId" element={<RegisterPage/>}/>

            <Route path="complaints" element={<Complaints/>}/>
            <Route path="complaint/:complaintId" element={<Complaint/>}/>

            {/*<Route path="checks" element={<Checks/>}/>*/}
            <Route path="get-services" element={<GetServices/>}/>
            <Route path="auth" element={<Auth/>}/>
            <Route path="page/scho-take-e-dozvil" element={<PageOne/>}/>
            <Route path="page/pytannja-ta-vidpovidy" element={<PageTwo/>}/>
            <Route path="page/perelik-poslug" element={<PageTree/>}/>
            <Route path="page/dogovir-pryednannja-dlja-partneriv" element={<PageFour/>}/>

            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )

}

export default Layout
/*
<Route path="page/:slug" element={<Page/>}/>
 */
const BaseUrl = 'https://epermit-public.brdo.com.ua/'
const ApiUrl = 'https://epermit-public.brdo.com.ua/api/'
const RegistryUrl = 'http://citizen-portal-brdo-main.apps.envone.dev.registry.eua.gov.ua/process-list'

const Registers = [
    {
        id: 1,
        title: "Реєстр у сфері ліцензування господарської діяльності з туроператорської діяльності (ДАРТ)",
        url: RegistryUrl,
        licencies: [
            {
                id: 1,
                global_data: {
                    license_num: "АГ580718",
                    rescive_num: "№ 15-ліц",
                    authority_name: "Державне агентство розвитку туризму",
                    title: "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"КЛУБ ПУТЕШЕСТВИЙ\"",
                    edrpou: "32688251",
                    type: "туроператорська діяльність (виїзний туризм, в’їзний туризм, внутрішній туризм)",
                    date_decision: "02.12.2011",
                    license_start: "02.12.2011",
                    date_acceptance: "02.12.2011",
                    license_term: "безстроково",
                    status: "Видано",
                    license_stop: "безстроково",
                    address: "вулиця Січеславська набережна, будинок 39, квартира 28, м. Дніпро, Дніпропетровська обл., 49000",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "554/2011",
                    data_3: "",
                    data_4: "вулиця Січеславська набережна, будинок 39, квартира 28, м. Дніпро, Дніпропетровська обл., 49000 "
                },
                history: [
                    {
                    pib: "Станко Віктор Петрович",
                    status: "ліцензію видано",
                    date: "22.03.2012"
                }]
            },
            {
                id: 2,
                global_data: {
                    license_num: "АГ580938",
                    rescive_num: "№ 31",
                    authority_name: "Державне агентство розвитку туризму",
                    title: "УКРАЇНСЬКО-СИРІЙСЬКЕ ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ ТА ІНОЗЕМНИМИ ІНВЕСТИЦІЯМИ \"ГАЛЬФ\"",
                    edrpou: "30004533",
                    type: "туроператорська діяльність (виїзний туризм, в’їзний туризм, внутрішній туризм)v",
                    date_decision: "22.03.2012",
                    license_start: "22.03.2012",
                    date_acceptance: "02.12.2011",
                    license_term: "безстроково",
                    status: "Видано",
                    license_stop: "безстроково",
                    address: "вулиця Комсомольська, будинок 40, корпус 1 Б, місто Дніпро, Дніпропетровська обл., 49000",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "144/2012",
                    data_3: "",
                    data_4: "вулиця Комсомольська, будинок 40, корпус 1 Б, місто Дніпро, Дніпропетровська обл., 49000"
                },
                history: [
                    {
                        pib: "Середюк Павло Карпович",
                        status: "ліцензію видано",
                        date: "22.03.2012"
                    }]
            },
            {
                id: 3,
                global_data: {
                    license_num: "АГ580941",
                    rescive_num: "№ 32",
                    authority_name: "Державне агентство розвитку туризму",
                    title: "ПРИВАТНЕ ПІДПРИЄМСТВО \"ДЖАМП ТРЕВЕЛ\"",
                    edrpou: "38112653",
                    type: "туроператорська діяльність (виїзний туризм, в’їзний туризм, внутрішній туризм)",
                    date_decision: "22.03.2012",
                    license_start: "22.03.2012",
                    date_acceptance: "22.03.2012",
                    license_term: "безстроково",
                    status: "Видано",
                    license_stop: "безстроково",
                    address: "місто Дніпропетровськ, вулиця Ширшова, будинок 4, офіс 102, 49000 ",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "147/2012",
                    data_3: "",
                    data_4: "місто Дніпропетровськ, вулиця Ширшова, будинок 4, офіс 102, 49000"
                },
                history: [
                    {
                        pib: "Станко Віктор Петрович",
                        status: "ліцензію видано",
                        date: "22.03.2012"
                    }]
            },
            {
                id: 4,
                global_data: {
                    license_num: "АГ581036",
                    rescive_num: "№ 43",
                    authority_name: "Державне агентство розвитку туризму",
                    title: "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ТУРИСТИЧНА КОМПАНІЯ \"ВІСТАНА\"",
                    edrpou: "38112716",
                    type: "туроператорська діяльність (виїзний туризм, в’їзний туризм)",
                    date_decision: "13.04.2012",
                    license_start: "13.04.2012",
                    date_acceptance: "13.04.2012",
                    license_term: "безстроково",
                    status: "Видано",
                    license_stop: "безстроково",
                    address: "вулиця Карла Лібкнехта, будинок 6, місто Дніпропетровськ, 49000",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "233/2012",
                    data_3: "",
                    data_4: "вулиця Карла Лібкнехта, будинок 6, місто Дніпропетровськ, 49000"
                },
                history: [
                    {
                        pib: "Пташник Роман Ігорович",
                        status: "ліцензію видано",
                        date: "13.04.2012"
                    }]
            },
            {
                id: 5,
                global_data: {
                    license_num: "АЕ272800",
                    rescive_num: "",
                    authority_name: "Державне агентство розвитку туризму",
                    title: "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ТУР ЕТНО\" 04053",
                    edrpou: "39233959",
                    type: "туроператорська діяльність (виїзний туризм, в’їзний туризм)",
                    date_decision: "",
                    license_start: "",
                    date_acceptance: "",
                    license_term: "безстроково",
                    status: "Анульована",
                    license_stop: "безстроково",
                    address: "місто Київ, вулиця Артема, будинок 37-41",
                    d1: "",
                },
                changes: {
                    data_1: "29.09.2015",
                    data_2: "",
                    data_3: "Наказ Мінекономрозвитку від 29.09.2015",
                    data_4: "місто Київ, вулиця Артема, будинок 37-41"
                },
                history: [
                    {
                        pib: "Заверюха Анна Сергіївна",
                        status: "ліцензію анульовано",
                        date: "29.09.2015"
                    }]
            },
            {
                id: 6,
                global_data: {
                    license_num: "08310309202200020",
                    rescive_num: "№ 50",
                    authority_name: "Державна податкова служба",
                    title: "ФІЗИЧНА ОСОБА - ПІДПРИЄМЕЦЬ  ШЕЛУДЧЕНКО АЛЬОНА ОЛЕКСАНДРІВНА",
                    edrpou: "35233111",
                    type: "туроператорська діяльність (внутрішній туризм)",
                    date_decision: "02.09.2021",
                    license_start: "02.09.2021",
                    date_acceptance: "",
                    license_term: "1 рік",
                    status: "Видано",
                    license_stop: "02.09.2022",
                    address: "М.ПАВЛОГРАД, ВУЛ.ДНІПРОВСЬКА, 460Б",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "",
                    data_3: "",
                    data_4: "М.ПАВЛОГРАД, ВУЛ.ДНІПРОВСЬКА, 460Б"
                },
                history: [
                    {
                        pib: "Колод Олександра Олександрівна",
                        status: "ліцензію видано",
                        date: "02.09.2021"
                    }]
            },
            {
                id: 7,
                global_data: {
                    license_num: "08310309202200039",
                    rescive_num: "№ 49",
                    authority_name: "Державна податкова служба",
                    title: "ФІЗИЧНА ОСОБА - ПІДПРИЄМЕЦЬ МАКСИМОВ ОЛЕГ ЕДУАРДОВИЧ",
                    edrpou: "34233125",
                    type: "туроператорська діяльність (внутрішній туризм)",
                    date_decision: "18.02.2021",
                    license_start: "18.02.2021",
                    date_acceptance: "",
                    license_term: "1 рік",
                    status: "Видано",
                    license_stop: "18.02.2022",
                    address: "ЗАПОРIЗЬКА ОБЛ., М. ЗАПОРІЖЖЯ, ХОРТИЦЬКИЙ Р-Н, пр.Ювілейний, 20-А",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "",
                    data_3: "",
                    data_4: "ЗАПОРIЗЬКА ОБЛ., М. ЗАПОРІЖЖЯ, ХОРТИЦЬКИЙ Р-Н, пр.Ювілейний, 20-А"
                },
                history: [
                    {
                        pib: "Путас Яна Володимирівна",
                        status: "ліцензію видано",
                        date: "18.02.2022"
                    }]
            },
            {
                id: 8,
                global_data: {
                    license_num: "08320309202100017",
                    rescive_num: "№ 108",
                    authority_name: "Державна податкова служба",
                    title: "ФІЗИЧНА ОСОБА - ПІДПРИЄМЕЦЬ ПОПОВ СЕРГІЙ ОЛЕКСАНДРОВИЧ",
                    edrpou: "38566523",
                    type: "туроператорська діяльність (виїзний туризм)",
                    date_decision: "21.02.2021",
                    license_start: "21.02.2021",
                    date_acceptance: "",
                    license_term: "1 рік",
                    status: "Видано",
                    license_stop: "21.02.2022",
                    address: "м. Запоріжжя, Хортицький район, пр.Ювілейний, 26 (приміщення №1)",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "",
                    data_3: "",
                    data_4: "м. Запоріжжя, Хортицький район, пр.Ювілейний, 26 (приміщення №1)"
                },
                history: [
                    {
                        pib: "Кіслиця Валентин Петрович",
                        status: "ліцензію видано",
                        date: "21.02.2022"
                    }]
            },
            {
                id: 9,
                global_data: {
                    license_num: "10380309202100101",
                    rescive_num: "№ 56",
                    authority_name: "Державна податкова служба",
                    title: "ФІЗИЧНА ОСОБА - ПІДПРИЄМЕЦЬ СТЕЦЮК ЯРОСЛАВ ГЕННАДІЙОВИЧ",
                    edrpou: "44567500",
                    type: "туроператорська діяльність (в’їзний туризм)",
                    date_decision: "22.11.2021",
                    license_start: "22.11.2021",
                    date_acceptance: "",
                    license_term: "1 рік",
                    status: "Видано",
                    license_stop: "21.02.2022",
                    address: "М. БУЧА, провулок Тихого Олекси, 3/34",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "",
                    data_3: "",
                    data_4: "М. БУЧА, провулок Тихого Олекси, 3/34"
                },
                history: [
                    {
                        pib: "Ващук Ольга Мар'янівна",
                        status: "ліцензію видано",
                        date: "22.11.2021"
                    }]
            },
            {
                id: 10,
                global_data: {
                    license_num: "11230309202100009",
                    rescive_num: "№ 100",
                    authority_name: "Державна податкова служба",
                    title: "ФІЗИЧНА ОСОБА - ПІДПРИЄМЕЦЬ КАРАБЛІН ВАЛЕРІЙ ВОЛОДИМИРОВИЧ",
                    edrpou: "34366598",
                    type: "туроператорська діяльність (в’їзний туризм, внутрішній туризм)",
                    date_decision: "18.05.2021",
                    license_start: "18.05.202118.05.2021",
                    date_acceptance: "",
                    license_term: "1 рік",
                    status: "Видано",
                    license_stop: "18.05.2022",
                    address: "КIРОВОГРАДСЬКА ОБЛ.,М. КРОПИВНИЦЬКИЙ, ФОРТЕЧНИЙ Р-Н, вулиця Академіка Корольова, буд. 25/6",
                    d1: "",
                },
                changes: {
                    data_1: "",
                    data_2: "",
                    data_3: "",
                    data_4: "КIРОВОГРАДСЬКА ОБЛ.,М. КРОПИВНИЦЬКИЙ, ФОРТЕЧНИЙ Р-Н, вулиця Академіка Корольова, буд. 25/6"
                },
                history: [
                    {
                        pib: "Віктюк Лариса Петрівна",
                        status: "ліцензію видано",
                        date: "18.05.2021"
                    }]
            },

        ]
    },
    {
        id: 2,
        title: "Реєстр у сфері ліцензування господарської діяльності з ветеринарної практики (ДПСС)",
        url: RegistryUrl
    },
    {
        id: 3,
        title: "Реєстр у сфері ліцензування господарської діяльності з культивування рослин, включених до таблиці I переліку наркотичних засобів, психотропних речовин і прекурсорів, розроблення, виробництво, виготовлення, зберігання, перевезення, придбання, реалізація (відпуск), ввезення на територію України, вивезення з території України, використання, знищення наркотичних засобів, психотропних речовин і прекурсорів, включених до зазначеного переліку (Держлікслужба)",
        url: RegistryUrl
    },
    {
        id: 4,
        title: "Реєстр у сфері ліцензування господарської діяльності з освітньої діяльності за рівнем дошкільної освіти (КМДА)",
        url: RegistryUrl
    },
    {id: 5, title: "Реєстр у сфері ліцензування роздрібної торгівлі алкогольними напоями (ДПС)", url: RegistryUrl},
    {id: 6, title: "Реєстр у сфері ліцензування оптової торгівлі алкогольними напоями (ДПС)", url: RegistryUrl},
    {id: 7, title: "Реєстр у сфері ліцензування роздрібної торгівлі тютюновими виробами (ДПС)", url: RegistryUrl},
    {id: 8, title: "Реєстр у сфері ліцензування оптової торгівлі тютюновими виробами (ДПС)", url: RegistryUrl},
    {
        id: 9,
        title: "Реєстр у сфері ліцензування роздрібної торгівлі рідинами, що використовується в електронних сигаретах (ДПС)",
        url: RegistryUrl
    },
    {
        id: 10,
        title: "Реєстр у сфері ліцензування оптової торгівлі рідинами, що використовується в електронних сигаретах (ДПС)",
        url: RegistryUrl
    },
    {
        id: 11,
        title: "Реєстр у сфері ліцензування права оптової торгівлі пальним за відсутності місць оптової торгівлі пальним (ДПС)",
        url: RegistryUrl
    },
    {
        id: 12,
        title: "Реєстр у сфері ліцензування права зберігання пального виключно для потреб власного споживання чи промислової переробки (ДПС)",
        url: RegistryUrl
    },
    {
        id: 13,
        title: "Реєстр у сфері декларування відповідності матеріально-технічної бази вимогам законодавства з питань охорони праці (Держпраці)",
        url: RegistryUrl
    },
    {
        id: 14,
        title: "Реєстр у сфері отримання дозволу на початок виконання робіт підвищеної небезпеки та початок експлуатації (застосування) машин, механізмів, устаткування підвищеної небезпеки (Держпраці)",
        url: RegistryUrl
    },
    {
        id: 15,
        title: "Реєстр скарг здобувачів ліцензій, ліцензіатів на дії (бездіяльність) органів ліцензування щодо порушення законодавства у сфері ліцензування (ДРС)",
        url: RegistryUrl
    }
]

const PopularServices = [
    {
        id: 1,
        title: "Послуги у сфері ліцензування господарської діяльності з туроператорської діяльності (ДАРТ)",
        image: "/_assets/img/services/1-tourism.jpeg",
        url: RegistryUrl
    },
    {
        id: 2,
        title: "Послуги у сфері ліцензування господарської діяльності з ветеринарної практики (ДПСС)",
        image: "/_assets/img/services/2-veterinary.jpeg",
        url: RegistryUrl
    },
    {
        id: 5,
        title: "Послуги у сфері ліцензування роздрібної торгівлі алкогольними напоями (ДПС)",
        image: "/_assets/img/services/5-wines.jpeg",
        url: RegistryUrl
    },
    {
        id: 6,
        title: "Послуги у сфері ліцензування оптової торгівлі алкогольними напоями (ДПС)",
        image: "/_assets/img/services/6-wine-house.jpeg",
        url: RegistryUrl
    },
    {
        id: 7,
        title: "Послуги у сфері ліцензування роздрібної торгівлі тютюновими виробами (ДПС)",
        image: "/_assets/img/services/7-cigarette.jpeg",
        url: RegistryUrl
    },
    {
        id: 8,
        title: "Послуги у сфері ліцензування оптової торгівлі тютюновими виробами (ДПС)",
        image: "/_assets/img/services/8-cigarette.jpeg",
        url: RegistryUrl
    }
]
const Services = [
    {
        id: 1,
        title: "Послуги у сфері ліцензування господарської діяльності з туроператорської діяльності (ДАРТ)",
        image: "/_assets/img/services/1-tourism.jpeg",
        url: RegistryUrl
    },
    {
        id: 2,
        title: "Послуги у сфері ліцензування господарської діяльності з ветеринарної практики (ДПСС)",
        image: "/_assets/img/services/2-veterinary.jpeg",
        url: RegistryUrl
    },
    {
        id: 3,
        title: "Послуги у сфері ліцензування господарської діяльності з культивування рослин, включених до таблиці I переліку наркотичних засобів, психотропних речовин і прекурсорів, розроблення, виробництво, виготовлення, зберігання, перевезення, придбання, реалізація (відпуск), ввезення на територію України, вивезення з території України, використання, знищення наркотичних засобів, психотропних речовин і прекурсорів, включених до зазначеного переліку (Держлікслужба)",
        image: "/_assets/img/services/3-cannabis.jpeg",
        url: RegistryUrl
    },
    {
        id: 4,
        title: "Послуги у сфері ліцензування господарської діяльності з освітньої діяльності за рівнем дошкільної освіти (КМДА)",
        image: "/_assets/img/services/4-school.jpeg",
        url: RegistryUrl
    },
    {
        id: 5,
        title: "Послуги у сфері ліцензування роздрібної торгівлі алкогольними напоями (ДПС)",
        image: "/_assets/img/services/5-wines.jpeg",
        url: RegistryUrl
    },
    {
        id: 6,
        title: "Послуги у сфері ліцензування оптової торгівлі алкогольними напоями (ДПС)",
        image: "/_assets/img/services/6-wine-house.jpeg",
        url: RegistryUrl
    },
    {
        id: 7,
        title: "Послуги у сфері ліцензування роздрібної торгівлі тютюновими виробами (ДПС)",
        image: "/_assets/img/services/7-cigarette.jpeg",
        url: RegistryUrl
    },
    {
        id: 8,
        title: "Послуги у сфері ліцензування оптової торгівлі тютюновими виробами (ДПС)",
        image: "/_assets/img/services/8-cigarette.jpeg",
        url: RegistryUrl
    },
    {
        id: 9,
        title: "Послуги у сфері ліцензування роздрібної торгівлі рідинами, що використовується в електронних сигаретах (ДПС)",
        image: "/_assets/img/services/9-vape-one.jpeg",
        url: RegistryUrl
    },
    {
        id: 10,
        title: "Послуги у сфері ліцензування оптової торгівлі рідинами, що використовується в електронних сигаретах (ДПС)",
        image: "/_assets/img/services/10-vape-many.jpeg",
        url: RegistryUrl
    },
    {
        id: 11,
        title: "Послуги у сфері ліцензування права оптової торгівлі пальним за відсутності місць оптової торгівлі пальним (ДПС)",
        image: "/_assets/img/services/11-petrol-cans.jpeg",
        url: RegistryUrl
    },
    {
        id: 12,
        title: "Послуги у сфері ліцензування права зберігання пального виключно для потреб власного споживання чи промислової переробки (ДПС)",
        image: "/_assets/img/services/12-truck.jpeg",
        url: RegistryUrl
    },
    {
        id: 13,
        title: "Послуги у сфері декларування відповідності матеріально-технічної бази вимогам законодавства з питань охорони праці (Держпраці)",
        image: "/_assets/img/services/13-declaration-work.jpeg",
        url: RegistryUrl
    },
    {
        id: 14,
        title: "Послуги у сфері отримання дозволу на початок виконання робіт підвищеної небезпеки та початок експлуатації (застосування) машин, механізмів, устаткування підвищеної небезпеки (Держпраці)",
        image: "/_assets/img/services/14-permit-work.jpeg",
        url: RegistryUrl
    },
    {
        id: 15,
        title: "Оскарження порушення законодавства у сфері ліцензування для здобувачів ліцензій, ліцензіатів щодо дій (бездіяльностьсть) органів ліцензування (ДРС)",
        image: "/_assets/img/services/15-complaint.jpeg",
        url: RegistryUrl
    }
]

const Authorities = [
    {
        id: 1,
        title: "ДАРТ",
        alias: "dart",
        image: "/_assets/img/brand/4.png",
        registries: [
            {
                id: 1,
                title: "Ліцензування господарської діяльності з туроператорської діяльності",
                slug: "dart-1",
                licencies: [
                    {
                        id: 1,
                        parent: "dart",
                        license_num: "АГ580941",
                        rescive_num: "№ 32",
                        title: "ПРИВАТНЕ ПІДПРИЄМСТВО \"ДЖАМП ТРЕВЕЛ\" ",
                        edrpou: "38112653",
                        type: "туроператорська діяльність",
                        license_start: "22.03.2012",
                        date_acceptance: "22.03.2012",
                        license_term: "безстроково",
                        status: "Видано",
                        license_term: "безстроково",
                        address: "місто Дніпропетровськ, вулиця Ширшова, будинок 4, офіс 102, 49000",
                        license_cancel_num: "147/2012",
                        history: {
                            pib: "Станко Віктор Петрович",
                            status: "ліцензію видано",
                            date: "22.03.2012"
                        }
                    },
                    {
                        id: 2,
                        parent: "dart",
                        license_num: "АГ580718",
                        rescive_num: "№ 15-ліц",
                        title: "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"КЛУБ ПУТЕШЕСТВИЙ\" ",
                        edrpou: "32688251",
                        type: "туроператорська діяльність",
                        license_start: "02.12.2011",
                        date_acceptance: "02.12.2011",
                        license_term: "безстроково",
                        status: "Видано",
                        license_term: "безстроково",
                        address: "вулиця Січеславська набережна, будинок 39, квартира 28, м. Дніпро, Дніпропетровська обл., 49000",
                        license_cancel_num: "554/2011",
                        history: {
                            pib: "Костенко Софія Олександрівна",
                            status: "ліцензію видано",
                            date: "02.12.2011"
                        }
                    },
                    {
                        id: 3,
                        parent: "dart",
                        license_num: "АГ580938",
                        rescive_num: "№ 31",
                        title: "УКРАЇНСЬКО-СИРІЙСЬКЕ ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ ТА ІНОЗЕМНИМИ ІНВЕСТИЦІЯМИ \"ГАЛЬФ\" ",
                        edrpou: "30004533",
                        type: "туроператорська діяльність",
                        license_start: "22.03.2012",
                        date_acceptance: "22.03.2012",
                        license_term: "безстроково",
                        status: "Видано",
                        license_term: "безстроково",
                        address: "вулиця Комсомольська, будинок 40, корпус 1 Б, місто Дніпро, Дніпропетровська обл., 49000",
                        license_cancel_num: "144/2012",
                        history: {
                            pib: "Середюк Павло Карпович",
                            status: "ліцензію видано",
                            date: "22.03.2012"
                        }
                    },
                    {
                        id: 4,
                        parent: "dart",
                        license_num: "АГ581036",
                        rescive_num: "№ 43",
                        title: "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ТУРИСТИЧНА КОМПАНІЯ \"ВІСТАНА\" ",
                        edrpou: "38112716",
                        type: "туроператорська діяльність",
                        license_start: "13.04.2012",
                        date_acceptance: "13.04.2012",
                        license_term: "безстроково",
                        status: "Видано",
                        license_term: "безстроково",
                        address: "вулиця Карла Лібкнехта, будинок 6, місто Дніпропетровськ, 49000 ",
                        license_cancel_num: "233/2012",
                        history: {
                            pib: "Станко Віктор Петрович",
                            status: "ліцензію видано",
                            date: "22.03.2012"
                        }
                    },
                    {
                        id: 5,
                        parent: "dart",
                        license_num: "АЕ272800",
                        rescive_num: "",
                        title: "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ТУР ЕТНО\" 04053",
                        edrpou: "39233959",
                        type: "туроператорська діяльність",
                        license_start: "",
                        date_acceptance: "",
                        license_term: "",
                        status: "Анульована",
                        license_term: "",
                        address: "місто Київ, вулиця Артема, будинок 37-41",
                        license_cancel_num: "233/2012",
                        history: {
                            pib: "Заверюха Анна Сергіївна",
                            status: "ліцензію анульовано",
                            date: "29.09.2015"
                        }
                    },
                ],
            }
        ],

        complaints: [
            {
                id: 1,
                parent: "dart",
                complaint_num: "223-46/24",
                context: "Анулювання ліцензії з порушенням вимог законодавства",
                date_decision: "22.01.2021",
                num_decision: "252",
                effective_date: "29.01.2021",
                message_date1: "29.01.2021",
                message_date2: "29.01.2021",
                date_order1: "25.02.2021",
                date_order2: "25.02.2021 Розглянуто",
                history: {
                    pib: "Шевченко Ганна Олегівна",
                    status: "Скаргу прийнято, розглянуто",
                    date: "10.02.2021"
                }
            },
            {
                id: 2,
                parent: "dart",
                complaint_num: "563-27/81",
                context: "Анулювання ліцензії з порушенням вимог законодавства",
                date_decision: "01.05.2021",
                num_decision: "1475",
                effective_date: "01.05.2021",
                message_date1: "01.05.2021",
                message_date2: "05.05.2021",
                date_order1: "25.02.2021",
                date_order2: "25.02.2021 Розглянуто",
                history: {
                    pib: "Кулик Віра Артемівна",
                    status: "Скаргу прийнято, запрошено до ЕАР.",
                    date: "02.05.2021"
                }
            },
            {
                id: 3,
                parent: "dart",
                complaint_num: "563-27/34",
                context: "Анулювання ліцензії з порушенням вимог законодавства",
                date_decision: "05.03.2021",
                num_decision: "9572",
                effective_date: "05.03.2021",
                message_date1: "05.03.2021",
                message_date2: "09.03.2021",
                date_order1: "26.03.2021",
                date_order2: "26.03.2021 Розглянуто",
                history: {
                    pib: "Зенько Станіслав Вікторович",
                    status: "Скаргу прийнято, розглянуто",
                    date: "04.03.2021"
                }
            },
            {
                id: 4,
                parent: "dart",
                complaint_num: "563-27/56",
                context: "Анулювання ліцензії з порушенням вимог законодавства",
                date_decision: "19.10.2021",
                num_decision: "498",
                effective_date: "19.10.2021",
                message_date1: "19.10.2021",
                message_date2: "20.10.2021",
                date_order1: "26.03.2021",
                date_order2: "26.03.2021 Розглянуто",
                history: {
                    pib: "Козак Сергій Петрович",
                    status: "Скаргу прийнято, запрошено до ЕАР.",
                    date: "25.10.2021"
                }
            },
            {
                id: 5,
                parent: "dart",
                complaint_num: "563-27/57",
                context: "Анулювання ліцензії з порушенням вимог законодавства",
                date_decision: "17.9.2021",
                num_decision: "3762",
                effective_date: "17.9.2021",
                message_date1: "17.9.2021",
                message_date2: "22.9.2021",
                date_order1: "30.9.2021",
                date_order2: "30.9.2021 Розглянуто",
                history: {
                    pib: "Тульчин Оксана Олександрівна",
                    status: "Скаргу прийнято, розглянуто.",
                    date: "21.09.2021"
                }
            }
        ]
    },
    {
        id: 2,
        title: "Держпраці",
        alias: "derzpraci",
        image: "/_assets/img/brand/7.png",
        registries: [
            {
                id: 2,
                title: "Декларації відповідності матеріально-технічної бази вимогам законодавства з питань охорони праці",
                slug: "derzpraci-1"
            },
            {
                id: 3,
                title: "Дозволи на початок виконання робіт підвищеної небезпеки та початок експлуатації (застосування) машин, механізмів, устаткування підвищеної небезпеки",
                slug: "derzpraci-2"
            }
        ]
    },
    {
        id: 3,
        title: "ДПСС",
        alias: "dpss",
        image: "/_assets/img/brand/6.png",
        registries: [
            {
                id: 4,
                title: "Ліцензування господарської діяльності з ветеринарної практики",
                slug: "dpss-1"
            }
        ]
    },
    {
        id: 4,
        title: "Держлікслужби",
        alias: "derzliksluzby",
        image: "/_assets/img/brand/5.png",
        registries: [
            {
                id: 5,
                title: "Ліцензування господарської діяльності з культивування рослин",
                slug: "derzliksluzby-1"
            }
        ]
    },
    {
        id: 5,
        title: "КМДА",
        alias: "kmda",
        image: "/_assets/img/brand/3.png",
        registries: [
            {
                id: 6,
                title: "Ліцензування господарської діяльності з освітньої діяльності за рівнем дошкільної освіти",
                slug: "kmda-1"
            }
        ]
    },
    {
        id: 6,
        title: "ДРС",
        alias: "drs",
        image: "/_assets/img/brand/3.png",
        registries: [
            {
                id: 7,
                title: "Реєстр розгляду скарг здобувачів ліцензій, ліцензіатів на дії (бездіяльність) органів ліцензування щодо порушення законодавства у сфері ліцензування",
                slug: "drs-1"
            },
            {
                id: 8,
                title: "Ліцензії на роздрібну торгівлю алкогольними напоями",
                slug: "drs-2"
            },
            {
                id: 9,
                title: "Ліцензії на оптову торгівлю алкогольними напоями",
                slug: "drs-3"
            },
            {
                id: 10,
                title: "Ліцензії на роздрібну торгівлю тютюновими виробами",
                slug: "drs-4"
            },
            {
                id: 11,
                title: "Ліцензії на оптову торгівлю тютюновими виробами",
                slug: "drs-5"
            },
            {
                id: 12,
                title: "Ліцензії на роздрібну торгівлю рідинами, що використовується в електронних сигаретах",
                slug: "drs-6"
            },
            {
                id: 13,
                title: "Ліцензії на право оптової торгівлі пальним за відсутності місць оптової торгівлі пальним",
                slug: "drs-7"
            }
        ]
    }
]

const Complaints = [
    {
        id: 1,
        global_data: {
            title: "",
            edrpou: "",
            d1: "223-46/24",
            d2: "Державне агентство розвитку туризму",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "22.01.2021",
            d5: "252",
            d6: "29.01.2021"
        },
        consideration_data: {
            d1: "29.01.2021",
            d2: "01.02.2021",
            d3: "",
            d4: "",
            d5: "25.02.2021",
            d6: "25.02.2021 Розглянуто"
        },
        history: [{
            pib: "Шевченко Ганна Олегівна",
            status: "Скаргу прийнято, розглянуто",
            date: "10.02.2021"
        }]
    },
    {
        id: 2,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/81",
            d2: "Державне агентство розвитку туризму",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "01.05.2021",
            d5: "1475",
            d6: "01.05.2021"
        },
        consideration_data: {
            d1: "01.05.2021",
            d2: "05.05.2021",
            d3: "",
            d4: "",
            d5: "25.02.2021",
            d6: "25.02.2021 Розглянуто"
        },
        history: [{
            pib: "Кулик Віра Артемівна",
            status: "Скаргу прийнято, запрошено до ЕАР.",
            date: "02.05.2021"
        }]
    },
    {
        id: 3,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/34",
            d2: "Державне агентство розвитку туризму",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "05.03.2021",
            d5: "9572",
            d6: "05.03.2021"
        },
        consideration_data: {
            d1: "05.03.2021",
            d2: "09.03.2021",
            d3: "",
            d4: "",
            d5: "26.03.2021",
            d6: "26.03.2021 Розглянуто"
        },
        history: [{
            pib: "Зенько Станіслав Вікторович",
            status: "Скаргу прийнято, розглянуто.",
            date: "04.03.2021"
        }]
    },
    {
        id: 4,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/56",
            d2: "Державне агентство розвитку туризму",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "19.10.2021",
            d5: "498",
            d6: "19.10.2021"
        },
        consideration_data: {
            d1: "19.10.2021",
            d2: "20.10.2021",
            d3: "",
            d4: "",
            d5: "",
            d6: ""
        },
        history: [{
            pib: "Козак Сергій Петрович",
            status: "Скаргу прийнято, запрошено до ЕАР",
            date: "25.10.2021"
        }]
    },
    {
        id: 5,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/57",
            d2: "Державне агентство розвитку туризму",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "17.09.2021",
            d5: "3762",
            d6: "17.09.2021"
        },
        consideration_data: {
            d1: "17.09.2021",
            d2: "22.09.2021",
            d3: "",
            d4: "",
            d5: "30.09.2021",
            d6: "30.09.2021 Розглянуто"
        },
        history: [{
            pib: "Тульчин Оксана Олександрівна",
            status: "Скаргу прийнято, розглянуто.",
            date: "21.09.2021"
        }]
    },
    {
        id: 6,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/76",
            d2: "Державне агентство розвитку туризму",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "20.08.2021",
            d5: "4005",
            d6: "20.08.2021"
        },
        consideration_data: {
            d1: "20.08.2021",
            d2: "23.08.2021",
            d3: "",
            d4: "",
            d5: "",
            d6: ""
        },
        history: [{
            pib: "Сороченко Ольга Вадимівна",
            status: "Скаргу прийнято, запрошено до ЕАР.",
            date: "27.08.2021"
        }]
    },
    {
        id: 7,
        global_data: {
            title: "eeee",
            edrpou: "33333",
            d1: "563-27/78",
            d2: "Державна податкова служба",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "10.11.2021",
            d5: "573",
            d6: "10.11.2021"
        },
        consideration_data: {
            d1: "10.11.2021",
            d2: "11.11.2021",
            d3: "",
            d4: "",
            d5: "",
            d6: ""
        },
        history: [{
            pib: "Тарасович Тарас Михайлович",
            status: "Скаргу прийнято, запрошено до ЕАР.",
            date: "10.11.2021"
        }]
    },
    {
        id: 8,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/53",
            d2: "Державна податкова служба",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "09.12.2021",
            d5: "8362",
            d6: "09.12.2021"
        },
        consideration_data: {
            d1: "09.12.2021",
            d2: "10.12.2021",
            d3: "",
            d4: "",
            d5: "23.12.2021",
            d6: "23.12.2021 Розглянуто"
        },
        history: [{
            pib: "Кісяч Сергій Володимирович",
            status: "Скаргу прийнято, розглянуто.",
            date: "09.12.2021"
        }]
    },
    {
        id: 9,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/98",
            d2: "Державна податкова служба",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "10.12.2021",
            d5: "9405",
            d6: "10.12.2021"
        },
        consideration_data: {
            d1: "10.12.2021",
            d2: "11.12.2021",
            d3: "",
            d4: "",
            d5: "23.12.2021",
            d6: "23.12.2021 Розглянуто"
        },
        history: [{
            pib: "Трубко Костянтин Валерійович",
            status: "Скаргу прийнято, розглянуто.",
            date: "10.12.2021"
        }]
    },
    {
        id: 10,
        global_data: {
            title: "",
            edrpou: "",
            d1: "563-27/90",
            d2: "Державна податкова служба",
            d3: "Анулювання ліцензії з порушенням вимог законодавства",
            d4: "11.12.2021",
            d5: "584",
            d6: "11.12.2021"
        },
        consideration_data: {
            d1: "11.12.2021",
            d2: "14.12.2021",
            d3: "",
            d4: "",
            d5: "16.12.2021",
            d6: "16.12.2021 Розглянуто"
        },
        history: [{
            pib: "Сурок Станіслав Олексійович",
            status: "Скаргу прийнято, розглянуто.",
            date: "11.12.2021"
        }]
    },


]

/*const Registers = [
    {id: 1, title: "Реєстр ДАРТ", url: RegistryUrl},
    {id: 2, title: "Реєстр Держпраці", url: RegistryUrl},
    {id: 3, title: "Реєстр ДПСС", url: RegistryUrl},
    {id: 4, title: "Реєстр Держлікслужби", url: RegistryUrl},
    {id: 5, title: "Реєстр КМДА", url: RegistryUrl},
    {id: 6, title: "Реєстр ДРС", url: RegistryUrl},
    {id: 7, title: "Реєстр ДПС алкоголь-тютюн", url: RegistryUrl},
    {id: 8, title: "Реєстр ДПС паливо", url: RegistryUrl}
]*/
export {BaseUrl, ApiUrl, RegistryUrl, PopularServices, Services, Complaints, Authorities, Registers}
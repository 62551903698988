import React from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import {Link} from "react-router-dom";
import RegistersList from "./Partial/Registers/RegistersList";
import Search from "../../Components/Search";

export function Registers() {

    return (
        <>
            <Header/>
            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                            Реєстри
                        </span>
                            </p>
                        </div>
                    </div>
                    <div className="heading heading--search">
                        <div className="heading__container container">
                            <h1 className="heading__title">Реєстри</h1>
                            <Search/>
                        </div>
                    </div>
                </div>
                <div className="main__content">
                    <RegistersList/>
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default Registers
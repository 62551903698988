import React from 'react'
import {useTable, useSortBy} from 'react-table'
import { useNavigate } from "react-router-dom";

export function Table({columns, data, urlPrefix}) {

    const {
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    )

    const firstPageRows = rows.slice(0, 20)

    const navigate = useNavigate()

    return (
        <>
            <table className="js-data-table">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                className={
                                    column.isSorted
                                        ? column.isSortedDesc
                                            ? "sorting sorting_desc"
                                            : "sorting sorting_asc"
                                        : ""
                                }>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}

                </thead>
                <tbody>
                {firstPageRows.map(
                    (row, i) => {
                        prepareRow(row);
                        let cellsLength = row.cells.length
                        return (
                            <tr {...row.getRowProps()} className="tr-block__link" onClick={() => navigate(urlPrefix + row.cells[0].row.original.id)} className="cursor-pointer">
                                {row.cells.map((cell, i, cells) => {
                                    if (i + 1 === cellsLength) {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                <span className="data__link">{cell.render('Cell')}</span>
                                            </td>)

                                    } else {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    }
                                })}
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
        </>
    )
}

export default Table;

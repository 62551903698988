import React, {useEffect, useState} from "react";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import {Link, useParams} from "react-router-dom";
import {Complaints} from "../../Constants/Global"
import Spoiler from "../../Components/Spoiler";

export const Complaint: React.FC = () => {
    const [complaint, setComplaint] = useState([])
    const [complaintTitle, setComplaintTitle] = useState([])


    useEffect(() => {
        document.body.classList.add('inner-page--result');

        fetchLicenciesList()

        return () => {
            document.body.classList.remove('inner-page--result');
        }
    }, [])

    const params = useParams();

    const {complaintId} = params

    async function fetchLicenciesList() {

        let item = []

        Complaints.forEach(function (complaint) {

            if (complaint.id == complaintId) {

                setComplaintTitle('Скарга №' + complaint.global_data.d1)
                item = complaint
            }
        })

        setComplaint(item)

        //const response = await axios.get('https://jsonplaceholder.typicode.com/photos?_limit=20')
        //setAuthoritiesList(response.data)
    }


    return (
        <>
            <Header/>

            <main className="main">

                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span>
                            <Link to='/complaints'>Скарги</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                                {complaintTitle}
                        </span>
                            </p>
                        </div>
                    </div>

                    <div className="heading">
                        <div className="heading__container container">
                            <h1 className="heading__title">{complaintTitle}</h1>
                            {/*<div className="heading__control">
                                <a className="heading__load btn" download>
                                    <svg className="btn__icon" width="20" height="20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.284 18.542c-4.092 0-5.842-1.75-5.842-5.842v-.108c0-3.7 1.459-5.484 4.725-5.792a.643.643 0 0 1 .684.567.626.626 0 0 1-.567.683c-2.617.242-3.592 1.475-3.592 4.55v.108c0 3.392 1.2 4.592 4.592 4.592h5.433c3.392 0 4.592-1.2 4.592-4.592V12.6c0-3.092-.992-4.325-3.658-4.55a.624.624 0 0 1 .108-1.242c3.317.284 4.8 2.075 4.8 5.8v.109c0 4.075-1.75 5.825-5.842 5.825H7.284Z"
                                            fill="#fff"/>
                                        <path
                                            d="M9.375 12.4V1.667A.63.63 0 0 1 10 1.042a.63.63 0 0 1 .625.625V12.4a.624.624 0 1 1-1.25 0Z"
                                            fill="#fff"/>
                                        <path
                                            d="m9.558 13.775-2.792-2.792a.629.629 0 0 1 0-.883.629.629 0 0 1 .884 0L10 12.45l2.35-2.35a.629.629 0 0 1 .883 0 .629.629 0 0 1 0 .883l-2.792 2.792a.618.618 0 0 1-.441.183.618.618 0 0 1-.442-.183Z"
                                            fill="#fff"/>
                                    </svg>
                                    <span className="btn__text">Завантажити</span>
                                </a>
                            </div>*/}
                        </div>
                    </div>

                </div>
                <div className="main__content">
                    <section className="spoiler spoiler--type-1">
                        <div className="spoiler__container container">
                            <div className="spoiler__list">

                                {complaint.global_data !== undefined ?
                                <div className="spoiler__item spoiler__item--general-data">
                                    <div className="spoiler__head">
                                        <h2 className="spoiler__title">Загальні дані</h2>
                                    </div>
                                    <div className="spoiler__body">
                                        <div className="spoiler__content">
                                            <div className="table-wrap">
                                                <table className="table">
                                                    {/*<caption>Інформація про здобувача ліцензії</caption>*/}
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <strong>Номер скарги</strong>
                                                        </td>
                                                        <td>{complaint.global_data.d1}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Найменування органу ліцензування, який здійснив дію (бездіяльність), що оскаржується</strong>
                                                        </td>
                                                        <td>
                                                            <span className="text-uppercase">{complaint.global_data.d2}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Зміст дії (бездіяльності) органу ліцензування, що оскаржується</strong>
                                                        </td>
                                                        <td>
                                                            <span className="text-uppercase">{complaint.global_data.d3}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Дата рішення органу ліцензування</strong>
                                                        </td>
                                                        <td>
                                                            <span className="text-uppercase">{complaint.global_data.d4}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Номер рішення органу ліцензування</strong>
                                                        </td>
                                                        <td>
                                                            <span className="text-uppercase">{complaint.global_data.d5}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Дата набрання ним чинності (за наявності)</strong>
                                                        </td>
                                                        <td>
                                                            <span className="text-uppercase">{complaint.global_data.d6}</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>: null }

                                {complaint.consideration_data !== undefined ?
                                <Spoiler title={'Розгляд скарги'}>
                                    <div className="table-wrap">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Дата і номер повідомлення ДРС про прийняття скарги до розгляду Експертно-апеляційною радою з питань ліцензування, надісланого органу ліцензування</strong>
                                                </td>
                                                <td>
                                                    <span className="text-uppercase">{complaint.consideration_data.d1}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Дата і номер повідомлення ДРС про прийняття скарги до розгляду Експертно-апеляційною радою з питань ліцензування, надісланого здобувачу ліцензії, ліцензіату, що подає скаргу</strong>
                                                </td>
                                                <td>
                                                    <span className="text-uppercase">{complaint.consideration_data.d2}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Інформація про зупинення рішення органу ліцензування, що оскаржується</strong>
                                                </td>
                                                <td>
                                                    <span className="text-uppercase">{complaint.consideration_data.d3}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Дата і номер рішення Експертно-апеляційної ради з питань ліцензування про відкладення розгляду скарги на наступне засідання (у разі прийняття такого рішення)</strong>
                                                </td>
                                                <td>
                                                    <span className="text-uppercase">{complaint.consideration_data.d4}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Дата і номер розпорядження ДРС про зняття скарги з розгляду</strong>
                                                </td>
                                                <td>
                                                    <span className="text-uppercase">{complaint.consideration_data.d5}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Spoiler>: null }

                                {complaint.history !== undefined ?
                                <Spoiler title={'Історія змін'}>
                                    <div className="table-wrap">
                                        <table className="table table--border">
                                            <thead>
                                            <tr>
                                                <th>ПІБі посадової особи, що внесла запис</th>
                                                <th>Зміни</th>
                                                <th>Дата зміни</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {complaint.history.map(item =>
                                                <tr>
                                                    <td>{item.pib}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.date}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Spoiler>: null }


                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default Complaint
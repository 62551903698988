import React, {useEffect, useState} from 'react';
import AuthoritiesListItem from './AuthoritiesListItem'
//import axios from "axios";
import {Authorities} from '../../../../Constants/Global'
import {useParams} from "react-router-dom";

export const AuthoritiesList: React.FC = () => {
    const [authoritiesList, setAuthoritiesList] = useState([])

    useEffect(() => {
        fetchAuthoritiesList()
    }, [])

    async function fetchAuthoritiesList() {
        //const response = await axios.get('https://jsonplaceholder.typicode.com/photos?_limit=20')
        //setAuthoritiesList(response.data)

        setAuthoritiesList(Authorities)
    }

    return (
        <>
            <section className="card-list">
                <div className="container">
                    <div className="grid">
                        {authoritiesList.map(authority =>
                            <AuthoritiesListItem item={authority} key={authority.id}/>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AuthoritiesList
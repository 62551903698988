import React from 'react';

export class Spoiler extends React.Component {

    constructor(props) {
        super(props)
        this.toggleSpoiler = this.toggleSpoiler.bind(this)

        this.state = { isOpen: false }
    }

    toggleSpoiler() {
        this.setState({isOpen: !this.state.isOpen})
    }

    render() {
        return (
            <div className={this.state.isOpen ? 'spoiler__item spoiler__item--open' : 'spoiler__item'}>
                <div className="spoiler__head js-spoiler-trigger" onClick={this.toggleSpoiler}>
                    <h2 className="spoiler__title">{this.props.title}</h2>
                </div>
                <div className="spoiler__body js-spoiler-target" onClick={this.toggleSpoiler} style={{display: this.state.isOpen ? 'block' : 'none' }}>
                    <div className="spoiler__content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

}

export default Spoiler;
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


export function DateInput({title, placeholder}) {

    const [startDate, setStartDate] = useState();

    return (<div className="grid__item">
        <div className="search-expand__input-group">
            <label className="search-expand__label">{title}</label>
            <div className="search-expand__t-date">
                <DatePicker className="search-expand__input js-datepicker" nativeInputAriaLabel={placeholder} locale="uk"
                            returnValue="range" showLeadingZeros={true} onChange={(date) => setStartDate(date)} dateFormat="dd.MM.yyyy"/>
            </div>
        </div>
    </div>)
}

export default DateInput
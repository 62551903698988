import React from "react";
import Header from "../../../Layouts/Header";
import Footer from "../../../Layouts/Footer";
import {Link} from "react-router-dom";

export function PageTree() {
    return (
        <>
            <Header/>

            <main className="main">
                <div className="main__head">
                    <div className="breadcrumb">
                        <div className="container">
                            <p className="breadcrumb__list">
                        <span>
                            <Link to='/'>Головна</Link>
                             »
                        </span>
                                <span className="breadcrumb_last">
                            Перелік послуг
                        </span>
                            </p>
                        </div>
                    </div>

                    <div className="heading heading--search">
                        <div className="heading__container container">
                            <h1 className="heading__title">Перелік послуг</h1>
                        </div>
                    </div>
                </div>
                <div className="main__content">
                    <section className="card-list">
                        <div className="container">

                            <p className="page-content">
                            </p>

                        </div>
                    </section>

                </div>

            </main>
            <Footer/>
        </>
    )
}

export default PageTree
import React from "react";
import PopularServices from "../Pages/Partial/PopularServices/PopularServices";
import SearchForm from "../../Components/SearchForm";

export function Home() {
    return (
        <main className="main">
            <section className="hero">
                <div className="hero__container container">
                    <div className="hero__inner">
                        <h1 className="hero__title">Єдина державна електронна система дозвільних документів</h1>
                        <SearchForm/>
                    </div>
                    <img className="hero__bg" src="/_assets/img/hero-bg.png" width="1420" height="674" alt="image"/>
                </div>
            </section>

            <PopularServices/>

        </main>
    )

}

export default Home
import React, {useState} from "react";
import {RegistryUrl} from "../Constants/Global";

const AutoComplete = ({data}) => {

    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [suggestionsActive, setSuggestionsActive] = useState(false);
    const [value, setValue] = useState("");

    const handleChange = (e) => {
        const query = e.target.value.toLowerCase();
        setValue(query);
        if (query.length > 1) {
            const filterSuggestions = data.filter(
                (suggestion) =>
                    suggestion.toLowerCase().indexOf(query) > -1
            );
            setSuggestions(filterSuggestions);
            setSuggestionsActive(true);
        } else {
            setSuggestionsActive(false);
        }
    };

    const handleClick = (e) => {
        setSuggestions([]);
        setValue('');
//        setValue(e.target.innerText);
        setSuggestionsActive(false);
    };

    const handleKeyDown = (e) => {
        // UP ARROW
        if (e.keyCode === 38) {
            if (suggestionIndex === 0) {
                return;
            }
            setSuggestionIndex(suggestionIndex - 1);
        }
        // DOWN ARROW
        else if (e.keyCode === 40) {
            if (suggestionIndex - 1 === suggestions.length) {
                return;
            }
            setSuggestionIndex(suggestionIndex + 1);
        }
        // ENTER
        else if (e.keyCode === 13) {
            setValue(suggestions[suggestionIndex]);
            setSuggestionIndex(0);
            setSuggestionsActive(false);
        }
    };

    const Suggestions = () => {
        return (
            <div className="resultBox" style={{display: suggestions && suggestions.length ? 'block' : 'none' }}>
                <ul className="suggestions">
                    {suggestions.map((suggestion, index) => {
                        return (
                            <li
                                className={index === suggestionIndex ? "active" : ""}
                                key={index}
                                onClick={handleClick}
                            ><a href={RegistryUrl} target="_blank" rel="noreferrer">{suggestion}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    function handleSubmit(e) {
        e.preventDefault();
    }

    return (
        <form className="hero-search" onSubmit={handleSubmit}>
            <div className="hero-search__group">
                <input className="hero-search__input" type="text" name="hero-search"
                       id="hero-search" placeholder="Пошук..."
                       value={value}
                       onChange={handleChange}
                       onKeyDown={handleKeyDown}
                />
                {suggestionsActive && <Suggestions/>}
                <button className="hero-search__btn">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M1.25 11C1.25 5.62 5.62 1.25 11 1.25C16.38 1.25 20.75 5.62 20.75 11C20.75 16.38 16.38 20.75 11 20.75C5.62 20.75 1.25 16.38 1.25 11ZM2.75 11C2.75 15.55 6.45 19.25 11 19.25C15.55 19.25 19.25 15.55 19.25 11C19.25 6.45 15.55 2.75 11 2.75C6.45 2.75 2.75 6.45 2.75 11Z"
                              fill="black" fillOpacity="0.5"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M19.9301 22.77C19.4601 22.71 18.6101 22.39 18.1301 20.96C17.8801 20.21 17.9701 19.46 18.3801 18.89C18.7901 18.32 19.4801 18 20.2701 18C21.2901 18 22.0901 18.39 22.4501 19.08C22.8101 19.77 22.7101 20.65 22.1401 21.5C21.4301 22.57 20.6601 22.79 20.1601 22.79C20.0801 22.79 20.0001 22.78 19.9301 22.77ZM20.1301 21.29C20.2901 21.31 20.5901 21.12 20.9001 20.67C21.1901 20.24 21.2101 19.93 21.1401 19.79C21.0701 19.65 20.7901 19.5 20.2701 19.5C19.9601 19.5 19.7301 19.6 19.6001 19.77C19.4801 19.94 19.4601 20.2 19.5601 20.49C19.7301 21.01 19.9701 21.27 20.1301 21.29Z"
                              fill="black" fillOpacity="0.5"/>
                    </svg>
                </button>
            </div>
            <label className="hero-search__label" htmlFor="hero-search">Наприклад: <span><a href={RegistryUrl}
                                                                                            target="_blank"
                                                                                            rel="noreferrer">ліцензія на провадження
                    туроператорської діяльності</a></span></label>


        </form>

    );

};

export default AutoComplete;